<template>
  <div class="venue-profile-group">
    <div
      class="venue-profile-group__photo"
      :style="{ backgroundImage: [`url(${profilePicture})`] }"
    />
    <div class="venue-profile-group__address">
      {{ venueName }}
    </div>
    <label
      v-show="$route.name == 'Venue Group Profile'"
      class="venue-profile-group__count"
    >
      {{ checkCount }}
    </label>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MenuProfile',
  props: {
    count: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      profilePicture: '',
      venueName: '',
    }
  },
  computed: {
    ...mapState('account', ['user']),
    checkCount() {
      const lastWord = this.count.length > 1 ? 'Venues' : 'Venue'
      return `${this.count.length} ${lastWord}`
    },
  },
  mounted() {
    const { profilePicture, companies } = this.user
    this.profilePicture = profilePicture
    this.venueName = companies[0].name || companies[0].fullname
  },
}
</script>

<style lang="scss" scoped>
.venue-profile-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 3rem 0rem 3.3rem;
  box-sizing: border-box;
  background-color: $primary;

  &__photo {
    display: block;
    width: 90px;
    height: 90px;
    background-color: $secondary;
    margin: 35px auto;
    border-radius: 50%;
    box-shadow: 1px 2px 15px 0px #00000036;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__address {
    @include montserrat-font($heading2, $white, 'bold');
    text-align: center;
  }

  &__count {
    @include montserrat-font($text1, $secondary, 'regular');
    text-align: center;
    margin: 20px 0px 0px 0px;
  }
}
</style>
