import state from './assets.state'
import mutations from './assets.mutations'
import actions from './assets.actions'
import getters from './assets.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
