import { find, isEmpty } from 'lodash'

export default {
  getDetail: (state) => (bookingId) => {
    // combinned 3 arrays to single array object.
    const allRequests = [
      ...state.pendingList.items,
      ...state.confirmedList.items,
      ...state.rejectedList.items,
    ]

    const result = find(allRequests, { bookingId })
    return result
  },
  isAllStatusLoaded: (state) => () => {
    return (
      state.pendingList.isLoaded &&
      state.confirmedList.isLoaded &&
      state.rejectedList.isLoaded
    )
  },
  getFirstItemFromList: (state) => (labelName) => {
    switch (labelName) {
      case 'New': {
        return isEmpty(state.pendingList.items)
          ? {}
          : state.pendingList.items[0]
      }
      case 'Confirmed': {
        return isEmpty(state.confirmedList.items)
          ? {}
          : state.confirmedList.items[0]
      }
      case 'Rejected': {
        return isEmpty(state.rejectedList.items)
          ? {}
          : state.rejectedList.items[0]
      }
      default: {
        return isEmpty(state.pendingList.items)
          ? {}
          : state.pendingList.items[0]
      }
    }
  },
  getItemFromBookingId: (state) => (bookingId) => {
    return state.pendingList.items.find((item) => item.bookingId == bookingId)
  },
  isRequestDetailAvailable: (state) => () => !isEmpty(state.requestDetail),
}
