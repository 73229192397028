export default {
  bookingByidSuccess: (state, payload) => {
    state.bookingData = { ...payload, isLoaded: true }
  },
  bookingByIdError: (state, payload) => (state.error = payload),
  setCardPaymentBooking: (state, payload) => {
    state.tokenCard = payload
  },
  setBillingAddress: (state, payload) => {
    state.billingAddress = payload
  },
  setPaymentType: (state, payload) => {
    state.requirePayment = payload
  },
  setMessageAlert: (state, payload) => {
    state.error = payload
  },
  RESCHEDULE(state, payload) {
    state.reschedule = payload
  },
  REMOVE_RESCHEDULE(state) {
    state.reschedule = null
  },
}
