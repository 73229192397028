import {
  MISSING_STAFF_OR_ASSET,
  MISSING_IMAGE,
  MISSING_ETA,
  MISSING_BOTH,
  showWarningMessage,
  MISSING_IMAGE_INVENTORY_ETA,
  MISSING_IMAGE_ETA,
  MISSING_INVENTORY_ETA,
} from '@/helpers/campaign/validation'

// Convertion functions
const _refineData = {
  stakeHolders: (originalStakeHolders) => {
    let stakeholders = []
    if (originalStakeHolders)
      stakeholders = originalStakeHolders.map((stakeholder) => ({
        _id: stakeholder.userId,
        role: stakeholder.role,
      }))
    return stakeholders
  },
  staffs: (originalStaffs) => {
    let staffs = []
    if (originalStaffs)
      staffs = originalStaffs.map((staff) => ({
        amount: staff.amount,
        _id: staff._id,
      }))
    return staffs
  },
  assets: (originalAssets) => {
    let assets = []
    if (originalAssets)
      assets = originalAssets.map((asset) => {
        const { _id, name, amount, image, code, contact, optional, maxAllocation, minAllocation } = asset
        let data = {
          _id,
          name,
          amount,
          imageUrl: image ? image.original : null,
          code,
          contact,
          optional,
        }

        if (maxAllocation || minAllocation) {
          data = {
            ...data,
            maxAllocation: Number(maxAllocation),
            minAllocation: Number(minAllocation),
          }
        }

        return data
      })
    return assets
  },
  stocks: (originalStocks) => {
    let stocks = []
    if (originalStocks)
      stocks = originalStocks.map((stock) => {
        const { _id, name, amount, price, type = '' } = stock
        return { _id, name, amount, price, type }
      })
    return stocks
  },
  venueIdentifiers: (originalitems) => {
    let items = []
    if (originalitems)
      items = originalitems.map((item) => {
        const { _id, name } = item
        return { _id, name }
      })
    return items
  },
  tasks: (originalTasks) => {
    let tasks = []
    if (originalTasks)
      tasks = originalTasks.map((task) => {
        const { deadline, isActive, stakeHolders, assignPOCode, slug, _id } =
          task

        const stakeHoldersInTask = stakeHolders.map((list) => ({
          _id: list.userId,
          role: list.role,
        }))
        const tasksTmp = {
          taskType: _id,
          deadline,
          isActive,
          stakeHolders: stakeHoldersInTask,
        }
        if (slug === 'sponsorship-requested') {
          tasksTmp.assignPOCode = assignPOCode
        }
        return tasksTmp
      })
    return tasks
  },
  venues: (list = []) => {
    return list.map(({ venue: name, venueId, companyId }) => ({
      name,
      venueId: '' + venueId,
      companyId,
    }))
  },
}

// getters
export default {
  // CAMPAIGN CREATION
  step1: (state) => {
    const isStaff = !!state.staffs.length
    return {
      name: state.name || '',
      description: state.description,
      slug: state.slug,
      // campaignType: state.campaignType,
      startDate: state.startDate,
      endDate: state.endDate,
      cutOffDate: state.cutOffDate,
      identifier_productCategories: state.identifier_productCategories,
      identifier_eventTypes: state.identifier_eventTypes,
      images: state.images || [],
      brandType: state.brandType,
      videoURL: state.videoURL,
      leadTime: state.leadTime,
      bookingDuration: isStaff ? 0 : state.bookingDuration,
      choiceSpecifyDateType: state.choiceSpecifyDateType,
      durationEta: state.durationEta,
      isStaff,
    }
  },
  step2: (state) => {
    return {
      staffs: state.staffs,
      assets: state.assets,
      varyAssets: state.varyAssets || [],
      stocks: state.stocks,
      requirements: state.requirements,
      additionRequirements: state.additionRequirements,
      brandType: state.brandType,
      brandServes: state.brandServes,
      links: state.links ? state.links : [],
      assetEditable: state.assetEditable || false,
      choiceAssetType: state.choiceAssetType || 'default',
      targetedTerritory: state.targetedTerritory,
    }
  },
  step3: (state) => {
    return {
      sponsorship: state.sponsorship,
      sponsorshipType: state.sponsorshipType,
      sponsorshipFund: state.sponsorshipFund,
      budget: state.budget,
      sponsorshipPreviewPrice: state.sponsorshipPreviewPrice,
      paidPreviewPrice: state.paidPreviewPrice,
    }
  },
  step4: (state) => {
    return {
      capacity: state.capacity,
      targeted: state.targeted,
      group: state.group,
      wholesaler: state.wholesaler,
      groupSelectAll: state.selectedAllFields
        ? state.selectedAllFields.customers
        : false,
      wholesalerSelectAll: state.selectedAllFields
        ? state.selectedAllFields.wholesalers
        : false,
      postcodesSelectAll: state.selectedAllFields
        ? state.selectedAllFields.postcodes
        : false,
      citiesSelectAll: state.selectedAllFields
        ? state.selectedAllFields.cities
        : false,
      targetedPostcodes: state.targetLocation
        ? state.targetLocation.postcodes
        : false,
      targetedCities: state.targetLocation
        ? state.targetLocation.cities
        : false,
      targetedStakeholders: state.targetedStakeholders
        ? state.targetedStakeholders
        : false,
      exclusiveOffer: state.exclusiveOffer ? state.exclusiveOffer : false,
      limitBooking: state.limitBooking,
      venueSelected: state.venueSelected,
      maxBookingPerVenue: state.maxBookingPerVenue,
    }
  },
  step5: (state) => {
    return {
      stakeHolders: state.stakeHolders ? state.stakeHolders : [],
      tasks: state.tasks,
      eventDuration: state.eventDuration,
      assetTurnaround: state.assetTurnaround,
    }
  },

  brandStakeholders: (state) => {
    return state.stakeHolders.filter(
      (stakeholder) => stakeholder.role === 'brand'
    )
  },

  brandLogo: (state) => {
    return state.brandType && state.brandType.logo
  },

  campaignImages: (state) => {
    return state.images
  },

  campaignName: (state) => {
    return state.name
  },

  campaignStartDate: (state) => {
    return state.startDate
  },

  campaignEndDate: (state) => {
    return state.endDate
  },

  campaignCutOffDate: (state) => {
    return state.cutOffDate
  },

  campaignID: (state) => {
    return state.id
  },

  isAttachedImages: (_, getters) => () => {
    const data = getters.saveData
    return data.profile && data.profile.images && data.profile.images.length > 4
  },

  isAttachedStaffOrAsset:
    ({ staffs, assets, varyAssets, territoryAssets }) =>
      () => {
        return (
          assets.length !== 0 || staffs.length !== 0 || varyAssets.length !== 0 || territoryAssets.length !== 0 
        )
      },

  invalidToPublished: (state, getters) => () => {
    const emptyEta =
      state.choiceSpecifyDateType === 'eta' && state.durationEta <= 0
    return !(
      getters.isAttachedImages() &&
      getters.isAttachedStaffOrAsset() &&
      !emptyEta
    )
  },

  showWarningMessage: (state, getters) => () => {
    const emptyEta =
      state.choiceSpecifyDateType === 'eta' && state.durationEta <= 0
    if (
      emptyEta &&
      !getters.isAttachedStaffOrAsset() &&
      !getters.isAttachedImages()
    ) {
      return showWarningMessage(MISSING_IMAGE_INVENTORY_ETA)
    } else if (emptyEta && !getters.isAttachedImages()) {
      return showWarningMessage(MISSING_IMAGE_ETA)
    } else if (emptyEta && !getters.isAttachedStaffOrAsset()) {
      return showWarningMessage(MISSING_INVENTORY_ETA)
    } else if (
      !getters.isAttachedStaffOrAsset() &&
      !getters.isAttachedImages()
    ) {
      return showWarningMessage(MISSING_BOTH)
    } else if (!getters.isAttachedStaffOrAsset()) {
      return showWarningMessage(MISSING_STAFF_OR_ASSET)
    } else if (!getters.isAttachedImages()) {
      return showWarningMessage(MISSING_IMAGE)
    } else if (emptyEta) {
      return showWarningMessage(MISSING_ETA)
    }
  },

  getSpecificTimeSlot: (state) => {
    return state.specificTimeSlot
  },
  getAssetImportRef: (state) => {
    return state.assetImportRef
  },
  getVaryAssets: (state) => {
    return state.varyAssets
  },

  saveData: (state) => {
    const isStaff = !!state.staffs.length
    let saveData = {}
    let profile = {}
    for (let key in state) {
      if (
        [
          'brandId',
          'campaigns',
          'slug',
          'status',
          'campaignDetail',
          '_id',
          'brandLogo',
          'sponsorshipType',
          'sponsorshipFund',
          'sponsorshipPreviewPrice',
          'paidPreviewPrice',
          'amount',
          'uploadCsvData',
          'fund',
          'choiceSpecifyDate',
          'brandTypeSelected',
          'campaignsList',
          'errorCampaign',
          'bakTargetedStakeholders',
          'experience_name',
          'territoryAssets',
          'fileName'
        ].indexOf(key) < 0 &&
        state[key] != null
      ) {
        switch (key) {
          case 'brandType':
            if (state.brandType) {
              saveData.brandType = {
                logo: state.brandType.logo,
                id: state.brandType._id,
                name: state.brandType.name,
              }
            } else {
              saveData.brandType = null
            }
            break
          case 'staffs':
            profile.staffTypes = _refineData.staffs(state.staffs)
            break
          case 'stakeHolders':
            profile.stakeHolders = _refineData.stakeHolders(state.stakeHolders)
            break
          case 'brandServes':
            if (state.brandType) {
              saveData.leadServes = state.brandServes.map((item) => item._id)
            }
            break
          case 'name':
            saveData.name = state[key]
            break
          case 'id':
            saveData._id = state[key]
            break
          case 'startDate':
          case 'endDate':
          case 'cutOffDate':
          case 'durationEta':
          case 'choiceSpecifyDateType':
            saveData[key] = state[key]
            break
          case 'leadTime':
            saveData[key] = state[key]
            break
          case 'exclusiveOffer':
            saveData[key] = state[key]
            break
          case 'images':
            profile[key] = state[key].map((item) => {
              return item.url
            })
            break
          case 'identifier_productCategories':
          case 'identifier_eventTypes':
            profile['identifiers'] = {
              productCategories:
                state.identifier_productCategories &&
                  state.identifier_productCategories.length
                  ? state.identifier_productCategories
                  : [],
              eventTypes:
                state.identifier_eventTypes &&
                  state.identifier_eventTypes.length
                  ? state.identifier_eventTypes.map((item) => item._id)
                  : [],
            }
            break
          case 'assets':
            profile[key] = _refineData.assets(state.choiceAssetType === 'territory_allocation' ? state.territoryAssets : state.assets)
            break
          case 'varyAssets':
            profile[key] = _refineData.assets(state.varyAssets)
            break
          case 'stocks':
            profile[key] = _refineData.stocks(state.stocks)
            break
          case 'targeted':
          case 'excluded':
          case 'capacity':
            profile['targetVenue'] = {
              capacity: { min: state.capacity[0], max: state.capacity[1] },
              venueIdentifiers: {
                included: _refineData.venueIdentifiers(state.targeted),
                excluded: _refineData.venueIdentifiers(state.excluded),
              },
            }
            break
          case 'group':
          case 'wholesaler':
            profile['targetGroup'] = {
              customers: state.group,
              wholesalers: state.wholesaler,
            }
            break
          case 'targetedStakeholders':
            profile.targetedStakeholders = state.targetedStakeholders
            break
          case 'limitBooking':
            profile.limitBooking = state.limitBooking
            break
          case 'bookingDuration':
            profile.bookingDuration = isStaff ? 0 : state.bookingDuration
            break
          case 'venueSelected':
            profile.targetedVenues = _refineData.venues(state.venueSelected)
            break
          case 'sponsorship':
          case 'budget':
            profile['sponsorship'] = {
              type: state.sponsorship,
              amount: {
                type: state.sponsorshipType,
                fund: state.sponsorshipFund,
              },
              sponsorshipPreviewPrice: state.sponsorshipPreviewPrice,
              paidPreviewPrice: state.paidPreviewPrice,
            }
            break
          case 'tasks':
            profile[key] = _refineData.tasks(state.tasks)
            break
          case 'links':
            profile.additionalLinks = state.links
            break
          case 'videoURL':
            saveData.videos = state.videoURL
            break
          case 'specificTimeSlot':
            saveData.specificTimeSlot = state[key]
            break
          default:
            profile[key] = state[key]
        }
      }
    }
    delete profile.fileName
    delete profile.territoryAssets
    saveData.profile = profile
   
    return saveData
  },
  getUploadCsvData: (state) => {
    return state.uploadCsvData
  },
  getPreviousSetting: (state) => {
    if (!state.campaignDetail._id || !state.campaignDetail.profile) return null
    const { profile } = state.campaignDetail
    const {
      choiceAssetType,
      assets,
      staffTypes,
      targetedStakeholders,
      targetedVenues,
    } = profile
    return {
      choiceAssetType,
      assets,
      targetedVenues,
      targetedStakeholders,
      staffTypes,
    }
  },
  getFileName: (state) => {
    return state.fileName
  },
  territoryAssets: (state) => {
    return state.territoryAssets
  }
}
