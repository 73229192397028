import {
  createActionHandlerMutate,
  createActionHandlerQuery,
} from '@/helpers/query'

export default {
  getBooking: createActionHandlerQuery('getBooking'),
  getAssetList: createActionHandlerQuery('getAssetList'),
  getAssetInventoryList: createActionHandlerQuery(
    'getAssetInventoryList',
    'getAssetsPagination'
  ),
  updateReturningAssetTrack: createActionHandlerMutate('returningAssetTrack'),
  getKeyContactNames: createActionHandlerQuery('getKeyContactNames'),
}
