/**
 * createActionHandler : Higher order function to return a new function for
 * experience action.
 * @param {String} mutationName - Name of mutation to be commit.
 */
const createActionHandlerQuery =
  (mutationName) =>
  async ({ commit }, { apollo, query, variables }) => {
    try {
      const response = await apollo.query({
        query,
        variables,
        debounce: 200,
      })
      commit(`${mutationName}Success`, response.data)
    } catch (error) {
      commit(`${mutationName}Error`, {
        message: `${mutationName} error`,
        error,
      })
    }
  }

export default {
  bookingByid: createActionHandlerQuery('bookingByid'),
  RESCHEDULE(context, payload) {
    context.commit('RESCHEDULE', payload)
  },
  REMOVE_RESCHEDULE(context, payload) {
    context.commit('REMOVE_RESCHEDULE', payload)
  },
}
