import { clearUserData, clearBeforePath } from '../helpers/localStorage'
import { handleRateReviewUrl } from '../helpers/checkPermitRoute'

import {
  createActionHandlerMutate,
  createActionHandlerQuery,
} from '@/helpers/query'

// initial state
const state = {
  user: false,
  id: false,
  email: false,
  // step 1
  venueName: false,
  venueAddress: false,
  venuePostcode: false,
  venueWebsite: false,
  wholesalers: [],
  customer: {},
  bookFor: false,
  location: false,
  city: false,
  countrySelected: null,
  isCountryIreland: false,
  venueCode: null,
  //step 2
  venueCapacity: 1000,
  venueIdentifiers: [],
  //step 3
  offeringBrandTypes: [],
  isOnboarding: true,
  error: null,
  //review
  venueWaitingReview: [],

  // Country from getting after signIn
  // Just have _id and shortName
  myCountry: false,

  // Country Details
  // Have postcode, cities, currentcy, tax etc.
  myCountryDetails: false,
  isSpecificTypeAttatch: null,
  needReload: false,

  userStates: {
    sponsorshipDashboard_banner: 'show',
    facilitator_banner: 'show',
    sponsorshipDashboard_filter: {
      bookingFilterToggle: 'my',
      searchText: '',
      tabName: 'new',
    },
  }
}

// getters
const getters = {
  userGroup: (state) => {
    if (state.user && state.user.companies)
      return state.user.companies.length ? state.user.companies[0].type : null
    return false
  },
  getFetchPolicy: (state) => {
    return state.needReload ? 'no-cache' : 'cache-first'
  },
  isAdminRole: (state) => {
    if (state.user.teamMember) {
      return state.user.teamMember.role === 'admin'
    }
    // TODO:
    // Note - for older account, there is no teamMember, then default is should be true
    // we can be recheck again when requirement changed.
    return true
  },
  isOnboarding: (state) => {
    return state.user.isOnboarding
  },
  isGeneralRole: (state) => {
    if (state.user.teamMember) {
      return state.user.teamMember.role === 'general'
    }
    return false
  },
  isBrandGeneralRole: (state) => {
    let userType = ''
    let role = ''
    if (state.user && state.user.companies)
      userType = state.user.companies.length
        ? state.user.companies[0].type
        : null
    if (state.user && state.user.teamMember) role = state.user.teamMember.role
    return userType === 'brand' && role === 'general'
  },
  isBrandAdminRole: (state) => {
    let userType = ''
    let role = ''
    if (state.user && state.user.companies)
      userType = state.user.companies.length
        ? state.user.companies[0].type
        : null
    if (state.user && state.user.teamMember) role = state.user.teamMember.role
    return userType === 'brand' && role === 'admin'
  },
  getTerritoryAssinged: (state) => {
    if (state.user && state.user.teamMember) {
      const territories = state.user.teamMember.territories
      if (territories && territories.length) {
        return territories.map((item) => item.territory)
      } else {
        return []
      }
    } else {
      return []
    }
  },
  getMyCountry: (state) => {
    return state.myCountryDetails
  },
  getUserRole: (state) => {
    if (!state.user || !state.user.teamMember) {
      return null
    }
    return state.user.teamMember.role
  },
  getUserPermission: (state) => {
    const NO_PERMISSION = 'NO_PERMISSION'

    if (state.user && state.user.companies) {
      const type = state.user.companies.length && state.user.companies[0].type
      const role = state.user.teamMember ? state.user.teamMember.role : 'admin'

      if (type && role) {
        return `${type}:${role}`
      }
      return NO_PERMISSION
    }
    return NO_PERMISSION
  },
  userRelatedBrands: (state) => {
    if (state.user && state.user.companies) {
      const userGroup = state.user.companies.length
        ? state.user.companies[0].type
        : null
      if (userGroup == 'brand') return state.user.companies[0].brandTypes
      if (userGroup == 'agency') {
        return state.user.companies[0].assignedCompanies
      }
    }
    return false
  },
  loggedInUser: (state) => {
    return state.user
  },
  waitingReview: (state) => {
    return state.venueWaitingReview
  },
  getVenueData: (state) => {
    return {
      venueName: state.venueName,
      venueAddress: state.venueAddress,
      venuePostcode: state.venuePostcode,
      venueWebsite: state.venueWebsite,
      wholesalers: state.wholesalers,
      customer: state.customer,
      venueCapacity: state.venueCapacity,
      venueIdentifiers: state.venueIdentifiers.map((item) => item._id),
      offeringBrandTypes: state.offeringBrandTypes.map((item) => item._id),
      countrySelected: state.countrySelected,
      isCountryIreland: state.isCountryIreland,
    }
  },
  saveAccountData: (state) => {
    const companyCode =
      state.venueCode && state.venueCode.venueId
        ? {
            brandCompany: state.venueCode.companyId,
            venueCode: String(state.venueCode.venueId),
          }
        : null
    return {
      _id: state.user.companies && state.user.companies[0]._id,
      name: state.venueName,
      address: state.venueAddress,
      wholesalers: state.wholesalers
        ? state.wholesalers.map((item) => item._id)
        : [],
      customer: state.customer ? state.customer._id : {},
      targetLocation: {
        lat: state.location ? state.location.lat : null,
        long: state.location ? state.location.lng : null,
        city: state.city ? state.city : null,
        postcode: state.venuePostcode,
      },
      holdAttandent: state.venueCapacity,
      venueIdentifiers: state.venueIdentifiers.map((item) => item._id),
      offeringBrandTypes: state.offeringBrandTypes,
      website: state.venueWebsite,
      isOnboarding: state.isOnboarding,
      primaryCountry: state.countrySelected && state.countrySelected._id,
      companyCode: (companyCode && [companyCode]) || null,
    }
  },
  userData: (state) => {
    return state.user
  },
  getCurrentUserEmail: (state) => () => state.user.email,
  getCompany: (state) => {
    return state.user && state.user.companies && state.user.companies[0]._id
  },
  getCompanyName: (state) => {
    return state.user && state.user.companies && state.user.companies[0].name
  },
  getCompanyData: (state) => () => {
    return state.user && state.user.companies && state.user.companies[0]
  },
  getBusinessDetail: (state) => () => {
    if (state.user && state.user.companies) {
      const businessDetails = state.user.businessDetails

      return {
        ...businessDetails,
        companyName:
          state.user.companies.length && state.user.companies[0].name,
        _id: state.user._id,
        profilePicture: state.user.profilePicture,
      }
    }
    return null
  },
  isHomeUser: (state) => {
    if (state.user.venueIdentifiers) {
      return state.user.venueIdentifiers.some(
        (item) => item._id === 'edefe0000000000000000027'
      )
    }
    return false
  },
  getPostcode: (state) => {
    if (
      state &&
      state.user &&
      state.user &&
      state.user.targetLocation &&
      state.user.targetLocation.postcode
    ) {
      return state.user.targetLocation.postcode
    }
    return ''
  },
  isSpecificTypeAttatch: (state) => state.isSpecificTypeAttatch,
  getVenueId(state) {
    let venueId = null
    if (state.bookFor) {
      venueId = state.bookFor.venueId
    } else {
      const [company] = state.user.companies || []
      if (company && company.companyCode.length) {
        venueId = company.companyCode[0].venueCode
      }
    }
    return venueId
  },
  getVenueTerritory(state) {
    let territory = ''
    if (state.bookFor) {
      territory = state.bookFor.territory_code
    }
    return territory
  },
  getBrandSetting(state) {
    return state.user && state.user.brandSetting
      ? state.user.brandSetting
      : { masterFile: false, imageCover: '' }
  },
  getVenueIdentifier(state) {
    return state.user.venueIdentifiers || []
  },
  targetedCustomer(state) {
    const type = getters.userGroup(state)
    let customers = []
    if (type === 'brand' && state.user) {
      customers = state.user.customers || []
    }
    return customers
  },
  targetedCountry(state) {
    const type = getters.userGroup(state)
    const companyData = getters.getCompanyData(state)()
    let countries = []
    if (type === 'brand' && state.user) {
      countries = companyData.targetCountries || []
    }
    return countries
  },
  getNotificationBadge(state) {
    const defaultBadge = {
      showAgencyDash: false,
      unReadAgencyDash: 0,
      unReadSponsorshipDash: 0
    };
    if (!state.user || !state.user.notificationBadge) {
      return defaultBadge;
    }
    return state.user.notificationBadge;
  }
}

// actions
const actions = {
  STORE_USER(context, payload) {
    context.commit('STORE_USER', payload)
  },
  SIGNIN(context, payload) {
    context.commit('SIGNIN', payload)
  },
  INIT_VENUE(context, payload) {
    context.commit('INIT_VENUE', payload)
  },
  SAVE_VENUE_STEP1(context, payload) {
    context.commit('SAVE_VENUE_STEP1', payload)
  },
  SAVE_VENUE_STEP2(context, payload) {
    context.commit('SAVE_VENUE_STEP2', payload)
  },
  SAVE_VENUE_STEP3(context, payload) {
    context.commit('SAVE_VENUE_STEP3', payload)
  },
  SIGN_OUT(context) {
    context.commit('SIGN_OUT')
  },
  BOOK_FOR(context, payload) {
    context.commit('BOOK_FOR', payload)
  },
  REMOVE_BOOK_FOR(context) {
    context.commit('REMOVE_BOOK_FOR')
  },
  CLEAR_REVIEW(context) {
    context.commit('CLEAR_REVIEW')
  },
  updateBusinessDetail: createActionHandlerMutate(
    'updateBusinessDetail',
    'accountUpdateById'
  ),
  UPDATE_STATE_FOR_VENUE(context, payload) {
    context.commit('UPDATE_STATE_FOR_VENUE', payload)
  },
  getReview: createActionHandlerMutate('checkingWaitingReview'),
  getMyCountryDetails: createActionHandlerQuery(
    'getMyCountryDetails',
    'countryById'
  ),
  SAVE_COUNTRY(context, payload) {
    context.commit('SAVE_COUNTRY', payload)
  },
  SIGNUP_WITH_TYPE(context, payload) {
    context.commit('SIGNUP_WITH_TYPE', payload)
  },
  SET_RELOAD(context, payload) {
    context.commit('SET_RELOAD', payload)
  },
  UPDATE_USER_STATE(context, payload) {
    context.commit('UPDATE_USER_STATE', payload)
  },
  CLEAR_USER_STATE(context) {
    context.commit('CLEAR_USER_STATE')
  }
}

// mutations
const mutations = {
  STORE_USER(state, payload) {
    state.user = payload
  },
  SIGNIN(state, payload) {
    state.email = payload.email
  },
  INIT_VENUE(state, payload) {
    state.id = payload._id
    state.venueAddress = payload.address
    state.venueName = payload.fullname
    state.venuePostcode = payload.postcode
    state.venueWebsite = payload.website
    state.wholesalers = payload.wholesalers
    state.customer = payload.customer
    state.venueCapacity = payload.holdAttandent ? payload.holdAttandent : 1000
    state.offeringBrandTypes = payload.offeringBrandTypes
  },
  SAVE_VENUE_STEP1(state, payload) {
    Object.assign(state, payload)
  },
  SAVE_VENUE_STEP2(state, payload) {
    Object.assign(state, payload)
  },
  SAVE_VENUE_STEP3(state, payload) {
    Object.assign(state, payload)
  },
  SIGN_OUT(state) {
    clearUserData()
    clearBeforePath()

    Object.assign(state, {
      user: false,
      id: false,
      email: false,
      venueName: false,
      venueAddress: false,
      venuePostcode: false,
      wholesalers: false,
      customer: false,
      venueIdentifiers: [],
    })

    state = {}
  },
  BOOK_FOR(state, payload) {
    state.bookFor = payload
  },
  REMOVE_BOOK_FOR(state) {
    Object.assign(state, {
      bookFor: false,
    })
  },
  CLEAR_REVIEW(state) {
    state.venueWaitingReview = []
  },
  updateBusinessDetailSuccess(state, payload) {
    state.user.businessDetails = {
      ...payload.record.businessDetails,
    }
  },
  UPDATE_USER_STATE(state, payload) {
    Object.assign(state.userStates, payload)
  },
  updateBusinessDetailError(state, payload) {
    state.error = payload
  },
  UPDATE_STATE_FOR_VENUE(state, payload) {
    for (let key in payload) state.user[key] = payload[key]
  },
  checkingWaitingReviewSuccess(state, payload) {
    state.venueWaitingReview = handleRateReviewUrl(
      payload.checkingWaitingReview
    )
  },
  checkingWaitingReviewError(state, payload) {
    state.error = payload
  },
  SAVE_COUNTRY(state, payload) {
    state.myCountry = {
      ...payload,
    }
  },
  getMyCountryDetailsSuccess(state, payload) {
    state.myCountryDetails = payload
  },
  getMyCountryDetailsError(state, payload) {
    state.error = payload
  },
  SIGNUP_WITH_TYPE(state, payload) {
    state.isSpecificTypeAttatch = payload
  },
  SET_RELOAD(state, payload) {
    state.needReload = payload
  },
  CLEAR_USER_STATE(state) {
    state.userStates.sponsorshipDashboard_filter = {
      bookingFilterToggle: 'my',
      searchText: '',
      tabName: 'new',
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
