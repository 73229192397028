import randomString from '@/helpers/randomString'

export default {
  savePlacesOnSuccess: (state, payload) => (state.places = payload),
  savePlacesOnError: (state, payload) => (state.errors = payload),
  savePlaceDetailOnSuccess: (state, payload) => (state.place = payload),
  savePlaceDetailOnError: (state, payload) => (state.errors = payload),
  saveAddressFromPostcodeOnSuccess: (state, payload) => {
    state.postcode = payload.postcode

    if (!payload.addresses) {
      state.addresses = [
        {
          text: payload.postcode,
          textAddress: payload.postcode,
          postcode: payload.postcode,
          city: null,
        },
      ]
      return
    }

    const newAddress = payload.addresses.map((data) => {
      const newData = {
        id: randomString(),
      }
      newData.text = `${payload.postcode} ${data.formatted_address
        .join(' ')
        .replace(/\s{2}/g, '')}`
      newData.textAddress = data.formatted_address
        .join(' ')
        .replace(/\s{2}/g, '')
      newData.postcode = payload.postcode
      newData.city =
        data.locality && data.locality !== ''
          ? data.locality
          : data.town_or_city
      return newData
    })

    state.addresses = newAddress
  },
  resetAddresses: (state) => (state.addresses = []),
  savePostcodeTag: (state, payload) => (state.postcode = payload),
  saveAddressFromPostcodeOnError: (state, payload) => (state.errors = payload),

  queryCountryManySuccess: (state, payload) => {
    state.countryMany = payload.countryMany
  },
  queryCountryManyError: (state, payload) => {
    state.error = payload
  },
  queryCountryOneSuccess: (state, payload) => {
    state.countryOne = payload.countryById
  },
  queryCountryOneError: (state, payload) => {
    state.error = payload
  },
}
