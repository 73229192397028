export default {
  staffType: {
    items: [],
    count: 0,
    isLoaded: false,
  },
  companyLists: [],
  staffTypeName: null,
  error: null,
}
