import {
  createActionHandlerQuery,
  createActionHandlerMutate,
} from '@/helpers/query'

export default {
  getStaffTypes: createActionHandlerQuery('getStaffTypes'),
  getCompanies: createActionHandlerQuery('getCompanies'),
  checkStaffTypeName: createActionHandlerQuery('checkStaffTypeName'),
  createStaffType: createActionHandlerMutate('createStaffType'),
  updateStaffType: createActionHandlerMutate('updateStaffType'),
}
