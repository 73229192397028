import find from 'lodash/find'

/**
 * Get post code from address_component (Google Place API)
 * @param {Array of object} addressComponents - collections of Address components
 * @returns {string} - Postal Code (long name) otherwise return empty string.
 */
export const getPostCode = (addressComponents = []) => {
  const postalCode = find(
    addressComponents,
    (data) => data.types && data.types.includes('postal_code')
  )
  return postalCode ? postalCode.long_name : ''
}
