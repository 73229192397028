/* eslint-disable no-unused-vars */
import alpha from 'color-alpha'
/**
 * Class for theme validation
 */
class ThemeSchema {
  constructor(schema = {}) {
    this.keys = Object.keys(schema)
    this.schema = schema
    return this.validate
  }

  validate = (props = {}) => {
    const merged = { ...this.schema, ...props }
    return this.keys.reduce((total, current) => {
      if (typeof merged[current] === 'function') {
        total[current] = merged[current].apply(merged)
      } else {
        total[current] = merged[current]
      }
      return total
    }, merged)
  }
}

/**
 * Sample of theme design
 */
const spaces = [
  0, // 0
  4, // 1
  8, // 2
  10, // 3
  16, // 4
  20, // 5
  30, // 6
  32, // 7
  64, // 8
  128, // 9
  256, // 10
  512, // 11
]

const fontSizes = {
  text: ['16px', '28px', '12px', '10px', '14px', '18px', '9px', '13px'],
  heading: [
    '40px', // 0
    '24px', // 1
    '22px', // 2
    '16px', // 3
    '12px', // 4
    '32px', // 5
    '42px', // 6
    '30px', // 7
    '60px', // 8
    '20px', // 9
    '28px', //10
  ],
}

const letterSpacings = {
  normal: 'normal',
  caps: '0.25em',
}

const lineHeights = [1, 1.125, 1.25, 1.5, 1.75]

const fontFamily = [
  'Inter',
  'Inter',
  'Inter',
  'Inter',
  'Inter',
]

const fontWeights = {
  light: 300,
  normal: 400,
  bold: 600,
  extraBold: 700,
  black: 900,
}

const breakpoints = {
  mobile: 'min-width: 320px',
  mobileLandscape: 'min-width: 480px',
  tablet: 'min-width: 768px',
  labtop: 'min-width: 1025px',
  smallDesktop: 'min-width: 1280px',
  normalDesktop: 'min-width: 1440px',
  extraDesktop: 'min-width: 1600px',
  forMobile: '(min-width: 320px) and (max-width: 480px)',
  forMobileToTablet: '(min-width: 320px) and (max-width: 768px)',
}

const defaultColor = {
  primary: '#2E2438',
  midGray: '#707070',
}

const baseTheme = new ThemeSchema({
  primary: '#2E2438',
  grey: '#D8D8D8',
  midGray: '#707070',
  lightGray: '#F3F3F3',
  grayDark2: '#8B8B8B',
  secondary: '#FACF92',
  tertiary: '#24B6F7',
  quaternary: '#FACF92',
  placeholder: '#3D3D3D',
  complimentary: '#738AF8',
  danger: '#FB453C',
  info: '#0096D5',
  warning: '#FFBD00',
  success: '#00C33F',
  suggest: '#6fcf97',
  navigation: '#0074cc',
  approve: '#448f63',
  notiAlert: '#CB112D',
  chart: {
    donut: {
      foreground: '#F7F5FA',
      fillColor: '#2E2438',
      alternative: '#738AF81A',
    },
    block: {
      foreground: '#ffffff',
      fillColor: '#2E2438',
      primary: '#FACF92',
      secondary: '#2E2438',
      tertiary: '#738AF8',
    },
  },
  shades: [
    '#ffffff', // 0
    '#D9D9D9', // 1
    '#707070', // 2
    '#3D3D3D', // 3
    '#000000', // 4
    '#F0F0F7', // 5
    '#FAE0D8', // 6
    '#A3A6B4', // 7
    '#F4F4F4', // 8
    '#E9E9E9', // 9
    '#E6E6E6', // 10
    '#F5F7FA', // 11
    '#D8D8D8', // 12
    '#959595', //13
    '#F3F3F3', // 14
    '#fbfbfb', // 15
    '#2e2438', //16
    '#E9F8FE', //17
    '#DEF4F6', //18
  ],
  /**
   * Colors that are derived from above colors.
   * @returns {string}
   */
  foreground() {
    return this.primary
  },
  background() {
    return this.shades[0]
  },
  borders() {
    return ['#FCD08E', this.shades[2], '#D9D9D9']
  },
  text() {
    const _this = this
    return {
      primary: _this.primary,
      button: _this.shades[0],
      secondary: _this.shades[13],
      link: _this.complimentary,
      disable: _this.shades[2],
      placeholder: _this.shades[1],
      gray: _this.shades[3],
      midGray: _this.shades[2],
      error: _this.danger,
    }
  },
  shadows() {
    return [`0px 10px 20px ${alpha('#000000', 0.15)}`]
  },
  spaces,
  lineHeights,
  breakpoints,
  letterSpacings,
  radius() {
    return {
      button: '5px',
      input: '5px',
      modal: '10px',
      box: '4px',
      campaignCard: '10px',
      card: '10px',
      tag: '15px',
    }
  },
  table() {
    return {
      body: {},
      tr: {
        background: this.shades[11],
      },
    }
  },
  font: {
    weight: fontWeights,
    family: fontFamily,
    sizes: fontSizes,
  },
  size: {
    width: {},
    height: {
      button: '44px',
      input: '44px',
    },
  },
  override() {
    return (prop, scope) => {
      switch (typeof prop) {
        case 'function':
          return prop(scope ? this[scope] : this)
        case 'string':
          return prop
        default:
          throw new Error('This prop should return string or function')
      }
    }
  },
  fontMontserrat(size = '16px', color = '#2E2438', weight = 'normal') {
    return (size, color, weight) => {
      return `
      color: ${color};
      font-family: ${fontFamily[1]};
      font-size: ${size};
      font-weight: ${fontWeights[weight]};
    `
    }
  },
  fontSF(size = '16px', color = defaultColor.primary, weight = 'normal') {
    return (size, color = defaultColor.primary, weight) => {
      return `
      color: ${color};
      font-family: ${fontFamily[0]};
      font-size: ${size};
      font-weight: ${fontWeights[weight]};
    `
    }
  },
  fontInter(size = '16px', color = defaultColor.primary, weight = 'normal') {
    return (size, color = defaultColor.primary, weight) => {
      return `
      color: ${color};
      font-family: ${fontFamily[4]};
      font-size: ${size};
      font-weight: ${fontWeights[weight]};
    `
    }
  },
  headerText(color = defaultColor.primary, size) {
    return (color = defaultColor.primary, size) => {
      return `
      color: ${color || defaultColor.primary};
      font-family: ${fontFamily[0]};
      font-size: ${size || fontSizes.heading[10]};
      font-weight: ${fontWeights['extraBold']};
      padding-bottom: ${spaces[5]}px;
    `
    }
  },
  normalText(color = defaultColor.midGray, size) {
    return (color = defaultColor.midGray, size) => {
      return `
      color: ${color || defaultColor.midGray};
      font-family: ${fontFamily[0]};
      font-size: ${size || fontSizes.text[0]};
      font-weight: ${fontWeights['normal']};
    `
    }
  },
  normalPrimary(color, size) {
    return (color, size) => {
      return `
      color: ${color || defaultColor.primary};
      font-family: ${fontFamily[0]};
      font-size: ${size || fontSizes.text[0]};
      font-weight: ${fontWeights['normal']};
    `
    }
  },
  boldText(color = defaultColor.primary, size) {
    return (color = defaultColor.primary, size) => {
      return `
      color: ${color || defaultColor.primary};
      font-family: ${fontFamily[0]};
      font-size: ${size || fontSizes.text[0]};
      font-weight: ${fontWeights['extraBold']};
    `
    }
  },
})

export const light = baseTheme()
export const dark = baseTheme({
  primary: light.shades[0],
  background: light.primary,
  shades: [...light.shades].reverse(),
})
