// import { getUserBasket } from '../../helpers/localStorage'


export default {
  myBasket: (state) => {
    if (state.isMassBooking && state.campaignMassBook) {
      return state.items.map((basket) => {
        const newBasket = JSON.parse(JSON.stringify(basket))
        Object.assign(newBasket, { campaign: state.campaignMassBook })
        Object.assign(newBasket.bookingData, {
          campaign: state.campaignMassBook,
        })
        return newBasket
      })
    }
    else if (state.isTerritoryMassBook && state.campaignMassBook
      && state.territoryMassBook
      && state.territoryMassBook.venues?.length) {

      return state.items.map((basket) => {
        const newBasket = JSON.parse(JSON.stringify(basket))

        const clone = JSON.parse(JSON.stringify(state.campaignMassBook))

        clone.profile.assets = newBasket.coreKits

        //combile add on
        if (newBasket.addOnAssets) {
          clone.profile.assets.push(...newBasket.addOnAssets)
        }

        Object.assign(newBasket, { campaign: state.campaignMassBook })
        Object.assign(newBasket.bookingData, {
          campaign: clone,
        })
        return newBasket
      })
    }

    return state.items
  },
  isShowBasketOverlay: (state) => {
    return state.isShowBasketOverlay
  },
  myDeliveryAddresses: (state) => {
    return state.deliveryAddresses
  },
  storeAddressTo: (state) => {
    return state.storeAddressTo
  },
  getDiscount: (state) => {
    return state.discount
  },
  isPaidBasket: (state) => {
    return state.isPaidBasket
  },
  basketDisplay: (state) => {
    return state.basketData
  },
  delierySelected: (state) => {
    return state.delierySelectedAddress
  },
  territoryMassbook: (state) => {
    return state.territoryMassBook
  },
  campaignMassBook: (state) => {
    return state.campaignMassBook
  }
}
