export default {
  setPendingRequestsSuccess: (state, { count, items }) => {
    state.pendingList = {
      count,
      items: [...state.pendingList.items, ...items],
      isLoaded: true,
    }
  },
  setConfirmedRequestsSuccess: (state, { count, items }) => {
    state.confirmedList = {
      count,
      items: [...state.confirmedList.items, ...items],
      isLoaded: true,
    }
  },
  setRejectedRequestsSuccess: (state, { count, items }) => {
    state.rejectedList = {
      count,
      items: [...state.rejectedList.items, ...items],
      isLoaded: true,
    }
  },

  setPendingRequestsError: (state, payload) => {
    state.pendingList = { ...state.pendingList, isLoaded: true }
    state.error = payload
  },
  setConfirmedRequestsError: (state, payload) => {
    state.confirmedList = { ...state.confirmedList, isLoaded: true }
    state.error = payload
  },
  setRejectedRequestsError: (state, payload) => {
    state.rejectedList = { ...state.rejectedList, isLoaded: true }
    state.error = payload
  },
  clearPendingRequests: (state) => {
    state.pendingList = {
      count: 0,
      items: [],
      isLoaded: false,
    }
  },
  clearConfirmedRequests: (state) => {
    state.confirmedList = {
      count: 0,
      items: [],
      isLoaded: false,
    }
  },
  clearRejectedRequests: (state) => {
    state.rejectedList = {
      count: 0,
      items: [],
      isLoaded: false,
    }
  },
  setRequestDetailSuccess: (state, payload) => (state.requestDetail = payload),
  clearRequestDetail: (state) => (state.requestDetail = {}),
  createConfirmRequestSuccess: (state, payload) => {
    state.requestCreate = { ...payload, created: true }
  },
  createConfirmRequestError: (state, payload) => {
    state.error = payload
  },
  createRejectRequestSuccess: (state, payload) => {
    state.requestReject = { ...payload, rejected: true }
  },
  createRejectRequestError: (state, payload) => {
    state.error = payload
  },
}
