import compose from '../helpers/compose'
import { config } from './index'

export const replaceWithEmail = (email) => (url) => url.replace(':email', email)
export const replaceWithCode = (code) => (url) => url.replace(':code', code)
export const replaceWithID = (id) => (url) => url.replace(':id', id)
export const replaceWithSlug = (slug) => (url) => url.replace(':slug', slug)
export const replaceWithActionName = (action) => (url) =>
  url.replace(':action', action)

export const publicUrl = () => {
  let url = 'https://app.dev.experiencethis.co'
  switch (config.env) {
    case 'develop':
      url = 'https://app.dev.experiencethis.co'
      break
    case 'uat':
      url = 'https://app.uat.experiencethis.co'
      break
    case 'demo':
      url = 'https://app.demo.experiencethis.co'
      break
    case 'production':
      url = 'https://app.experiencethis.co'
      break
    default:
      url = 'https://app.experiencethis.co'
  }
  return url
}

const CONSTANTS = {
  HOME: '/',
  WELCOME: '/welcome',
  ACCOUNT: {
    SIGNUP: '/account/signup/',
    SIGNUP_FOR_TEAM: '/account/signup/team/',
    SIGNUP_GENERAL: '/account/signup/general',
    SIGNIN: '/account/signin/',
    CONFIRM_EMAIL: '/account/confirm/:email',
    FORGOT_PASSWORD: '/account/forgot',
    RESET_PASSWORD: '/account/reset/:email/:code',
  },
  EXPERIENCE: {
    DASHBOARD: '/experience/dashboard',
    TIMELINE: '/experience/timeline/:id',
    DETAIL: '/experience/book/:id',
    BOOKING: '/experience/booking/:id',
    PAYMENT: '/experience/book/:id/payment',
    BOOKING_RESULT: '/experience/book/:id/result',
    PREVIEW: '/experience/preview/:id',
  },
  CAMPAIGN: {
    MARKETPLACE: '/marketplace',
    PROFILE: '/campaign/profile/:slug',
    PUBLIC: '/campaign/public/:slug',
    PREVIEW: '/campaign/preview/:slug',
    DASHBOARD: '/campaign/dashboard',
    CREATE: '/campaign/create/overview',
    CREATE_REQUIREMENT: '/campaign/create/requirements',
    CREATE_VENUE: '/campaign/create/venue',
    CREATE_BUDGETS: '/campaign/create/budgets',
    CREATE_STAKEHOLDERS: '/campaign/create/stakeholders',
    CREATE_COMPLETE: '/campaign/create/complete',
    EDIT: '/campaign/edit',
    EDIT_REQUIREMENT: '/campaign/edit/requirements',
    EDIT_VENUE: '/campaign/edit/venue',
    EDIT_BUDGETS: '/campaign/edit/budgets',
    EDIT_STAKEHOLDERS: '/campaign/edit/stakeholders',
    EDIT_COMPLETE: '/campaign/edit/complete',
  },
  VENUE: {
    CREATE: '/venue/create',
    CREATE_ABOUT: '/venue/create/about',
    CREATE_OFFERINGS: '/venue/create/offerings',
    MY_VENUE: '/my-venues',
    VENUES: '/venues',
  },
  MANAGEMENT: {
    CAMPAIGN: '/management/campaign',
    ASSET: '/management/asset',
    STOCK: '/managemment/stock',
    BUDGET: '/management/budget',
    TEAM: '/management/team',
    RATE_AND_REVIEW: '/management/rate-and-review',
  },
  NOTIFICATION: {
    BOOKING_APPROVAL: '/parse-email/booking-approval',
    ACTIVATION: '/parse-email/exp-activation-status',
  },
  VENUES: {
    LIST: '/venues',
    MY_VENUES: '/my-venues',
    PROFILE: '/venue/profile',
    GROUP_PROFILE: '/venues/group/profile',
  },
  ERROR: {
    BAD_REQUEST: '/400',
    NOT_FOUND: '/404',
    FORBIDDEN: '/403',
  },
  SPONSORSHIP_REQUEST: {
    LIST: '/requests',
  },
  BOOKING_REQUEST: {
    LIST: '/requests/booking',
  },
  CALENDAR: {
    MAIN: '/calendar',
  },
  BASKET: {
    SUMMARY: '/basket',
    CHECKOUT: '/basket/checkout',
    DELIVERY: '/basket/delivery',
  },
  API: {
    VENUE_DATA: '/json/venueData.min.json',
  },
}

export const createConfirmEmailUrl = (email) => {
  const url = CONSTANTS.ACCOUNT.CONFIRM_EMAIL
  return replaceWithEmail(email)(url)
}

export const createResetPasswordUrl = (email, code) => {
  const url = CONSTANTS.ACCOUNT.RESET_PASSWORD

  return compose(replaceWithEmail(email), replaceWithCode(code))(url)
}

export const createExperienceTimelineUrl = (id) => {
  const url = CONSTANTS.EXPERIENCE.TIMELINE
  return replaceWithID(id)(url)
}

export const createExperiencePreviewUrl = (id) => {
  const url = CONSTANTS.EXPERIENCE.PREVIEW
  return replaceWithID(id)(url)
}

export const createExperienceBookingDetailUrl = (id) => {
  const url = CONSTANTS.EXPERIENCE.DETAIL
  return replaceWithID(id)(url)
}

export const createExperienceBookingUrl = (id) => {
  const url = CONSTANTS.EXPERIENCE.BOOKING
  return replaceWithID(id)(url)
}

export const createExperienceBookingPaymentUrl = (id) => {
  const url = CONSTANTS.EXPERIENCE.PAYMENT
  return replaceWithID(id)(url)
}

export const createExperienceBookingResultUrl = (id) => {
  const url = CONSTANTS.EXPERIENCE.BOOKING_RESULT
  return replaceWithID(id)(url)
}

export const createCampaignProfileUrl = (slug) => {
  const url = CONSTANTS.CAMPAIGN.PROFILE
  return replaceWithSlug(slug)(url)
}

export const createCampaignPreviewUrl = (slug) => {
  const url = CONSTANTS.CAMPAIGN.PREVIEW
  return replaceWithSlug(slug)(url)
}

export const createStakeholdersRouteUrl = (action) => {
  const url = '/campaign/:action/stakeholders'
  return replaceWithActionName(action)(url)
}

export const getMyVenuePage = (action) => {
  const url = CONSTANTS.VENUES.MY_VENUES
  return replaceWithActionName(action)(url)
}

export const restAPIvideo = () => {
  let url = 'https://gateway.prod.experiencethis.co/files-rest-api'
  switch (config.env) {
    case 'develop':
      url =
        'http://api.dev.experiencethis.co:4025/rest-api/upload/vdo-digital-asset'
      break
    case 'development':
      url =
        'http://api.dev.experiencethis.co:4025/rest-api/upload/vdo-digital-asset'
      break
    case 'uat':
      url =
        'http://api.uat.experiencethis.co:4025/rest-api/upload/vdo-digital-asset'
      break
    case 'demo':
      url = 'https://gateway.demo.experiencethis.co/files-rest-api'
      break
    case 'production':
      url = 'https://gateway.prod.experiencethis.co/files-rest-api'
      break
    default:
      url = 'https://gateway.prod.experiencethis.co/files-rest-api'
  }
  return url
}

export default CONSTANTS
