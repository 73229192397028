import styled from 'vue3-styled-components'

const tableProps = {
  columns: Number,
  gap: Number,
  custom: String,
  type: String,
  noPadding: Boolean,
  columnsMobile: Number,
  customMobile: String,
}

export const TableGrid = styled('div', tableProps)`
  ${({
    columns,
    custom,
    type,
    gap,
    noPadding,
    columnsMobile,
    customMobile,
    theme: {
      shades,
      spaces,
      breakpoints,
      font: { sizes, family, weight },
    },
  }) => `
    display: grid;
    grid-template-columns: ${
      columns
        ? `repeat(${columnsMobile || columns}, minmax(10px, 1fr))`
        : customMobile || custom
    };
    grid-gap: ${gap}px;
    ${
      type === 'thread'
        ? `
      > div {
        background-color: ${shades[8]};
        font-size: ${sizes.text[4]};
        font-weight: ${weight.bold};
        font-family: ${family[1]};
        color: ${shades[7]};
        text-align: center;
        padding: ${spaces[4]}px 0;
      }
    `
        : type === 'item'
        ? `
      > div {
        padding: ${spaces[3]}px 0;
        border-bottom: 2px solid ${shades[5]};
      }
    `
        : `
      padding: ${spaces[3]}px 0;
    `
    }
    ${noPadding ? 'padding: 0;' : ''}
    @media (${breakpoints.tablet}) {
      max-height: unset;
      grid-template-columns: ${
        columns ? `repeat(${columns}, minmax(10px, 1fr))` : custom
      };
    }
    // max-height: 150px;
  `}
`
