// initial state
const state = {
  brandType: {},
  campaignImages: [],
  campaignData: {},
  assets: [],
  // Experience Booking
  bookingData: {
    dateOfExperience: null,
    footfall: {
      min: 200,
      max: 350,
    },
    stock: false,
    stockSold: null,
    orders: [],
    wholesaler: {
      name: null,
      email: null,
      phone: null,
    },
    facility: [],
    accessibility: [],
    isSuccess: false,
    specialRequest: '',
    contact: {
      name: '',
      phone: '',
    },
  },
  createBookingSuccess: false,
  isMetRequirement: false,
  isAvaliable: false,
  createBookingData: {
    eventStart: '',
    eventEnd: '',
    expectedAttandent: {
      min: 200,
      max: 250,
    },
    bookedBy: {
      userId: '',
      name: '',
    },
    product: {
      isStock: true,
      volumeSold: null,
    },
    stakeHolders: [
      {
        name: '',
        contactEmail: '',
        contactPhone: '',
        role: 'wholesaler',
      },
    ],
    specialRequests: '',
    contextRequests: '',
    driveSales: null,
    venueContact: {
      phone: '',
      name: '',
    },
    profit: {
      isActive: false,
      stocks: false,
    },
    campaign: {},
    quantity: 0,
  },
  isEditBookingMode: false,
  publicBookingData: {
    slug: '',
  },
}

// getters
const getters = {
  footfall: (state) => {
    return [state.bookingData.footfall.min, state.bookingData.footfall.max]
  },
  createBookingData: (state) => {
    return state.createBookingData
  },
  isEditBookingModeData: (state) => {
    return state.isEditBookingMode
  },
  publicBookingData: (state) => {
    return state.publicBookingData
  },
  createBookingSuccess: (state) => {
    return state.createBookingSuccess
  },
  brandType: (state) => {
    return state.brandType
  },
  campaignImages: (state) => {
    return state.campaignImages
  },
  getAssets: (state) => {
    return state.assets
  },
  getAvaliableAssets: (state) => {
    return state.isAvaliable
  },
  getMetRequirement: (state) => {
    return state.isMetRequirement
  },
}

// actions
const actions = {
  STORE_CAMPAIGN_IMAGES(context, payload) {
    context.commit('STORE_CAMPAIGN_IMAGES', payload)
  },
  STORE_ASSET(context, payload) {
    context.commit('STORE_ASSET', payload)
  },
  STORE_BRANDTYPE(context, payload) {
    context.commit('STORE_BRANDTYPE', payload)
  },
  STORE_CAMPAIGN(context, payload) {
    context.commit('STORE_CAMPAIGN', payload)
  },
  // Experience Booking
  STORE_SAVEBOOKIN_EDIT_DATA(context, payload) {
    context.commit('STORE_SAVEBOOKIN_EDIT_DATA', payload)
  },
  STORE_CLEAR_EDIT_BOOKING_DATA(context, payload, bookingId) {
    context.commit('STORE_CLEAR_EDIT_BOOKING_DATA', payload, bookingId)
  },
  STORE_SAVEBOOKINGDATA(context, payload) {
    context.commit('STORE_SAVEBOOKINGDATA', payload)
  },
  STORE_SAVE_PUBLIC_BOOKING(context, payload) {
    context.commit('STORE_SAVE_PUBLIC_BOOKING', payload)
  },
  STORE_EXPERIENCEDATE(context, payload) {
    context.commit('STORE_EXPERIENCEDATE', payload)
  },
  STORE_FOOTFALL(context, payload) {
    context.commit('STORE_FOOTFALL', payload)
  },
  STORE_STOCK(context, payload) {
    context.commit('STORE_STOCK', payload)
  },
  STORE_STOCKSOLD(context, payload) {
    context.commit('STORE_STOCKSOLD', payload)
  },
  INIT_ORDERS(context, payload) {
    context.commit('INIT_ORDERS', payload)
  },
  STORE_ORDERS(context, payload) {
    context.commit('STORE_ORDERS', payload)
  },
  STORE_WHOLESALER_NAME(context, payload) {
    context.commit('STORE_WHOLESALER_NAME', payload)
  },
  STORE_WHOLESALER_EMAIL(context, payload) {
    context.commit('STORE_WHOLESALER_EMAIL', payload)
  },
  STORE_WHOLESALER_PHONE(context, payload) {
    context.commit('STORE_WHOLESALER_PHONE', payload)
  },
  STORE_FACILITY(context, payload) {
    context.commit('STORE_FACILITY', payload)
  },
  STORE_ACCESSIBILITY(context, payload) {
    context.commit('STORE_ACCESSIBILITY', payload)
  },
  STORE_SPECIALREQUEST(context, payload) {
    context.commit('STORE_SPECIALREQUEST', payload)
  },
  STORE_CONTACT_NAME(context, payload) {
    context.commit('STORE_CONTACT_NAME', payload)
  },
  STORE_CONTACT_PHONE(context, payload) {
    context.commit('STORE_CONTACT_PHONE', payload)
  },
  STORE_IS_SUCCESS(context, payload) {
    context.commit('STORE_IS_SUCCESS', payload)
  },
  BOOKING_MEET_REQUIREMENT(context, payload) {
    context.commit('BOOKING_MEET_REQUIREMENT', payload)
  },
  BOOKING_ASSET_AVALIABLE(context, payload) {
    context.commit('BOOKING_ASSET_AVALIABLE', payload)
  },
  UPDATE_PRICE_BREAKDOWN(context, payload) {
    context.commit('UPDATE_PRICE_BREAKDOWN', payload)
  },
  UPDATE_CONTACT(context, payload) {
    context.commit('UPDATE_CONTACT', payload)
  },
}

// mutations
const mutations = {
  STORE_CAMPAIGN_IMAGES(state, payload) {
    state.campaignImages = payload
  },
  STORE_BRANDTYPE(state, payload) {
    state.brandType = payload
  },
  STORE_CAMPAIGN(state, payload) {
    state.campaignData = payload
  },
  STORE_ASSET(state, payload) {
    state.assets = payload
  },
  // Experience Booking
  STORE_SAVEBOOKINGDATA(state, payload) {
    state.isEditBookingMode = false
    state.createBookingData = payload
  },
  STORE_SAVEBOOKIN_EDIT_DATA(state, payload) {
    state.isEditBookingMode = true
    state.createBookingData = payload
  },
  STORE_CLEAR_EDIT_BOOKING_DATA(state) {
    state.createBookingData = {
      eventStart: '',
      eventEnd: '',
      expectedAttandent: {
        min: 200,
        max: 250,
      },
      bookedBy: {
        userId: '',
        name: '',
      },
      product: {
        isStock: true,
        volumeSold: null,
      },
      stakeHolders: [
        {
          name: '',
          contactEmail: '',
          contactPhone: '',
          role: 'wholesaler',
        },
      ],
      specialRequests: '',
      contextRequests: '',
      driveSales: null,
      venueContact: {
        phone: '',
        name: '',
      },
      profit: {
        isActive: false,
        stocks: false,
      },
      campaign: {},
      quantity: 0,
    }
    state.isEditBookingMode = false
  },
  UPDATE_CONTACT(state, payload) {
    state.createBookingData.venueContact = payload
  },
  STORE_SAVE_PUBLIC_BOOKING(state, payload) {
    state.publicBookingData = payload
  },
  STORE_EXPERIENCEDATE(state, payload) {
    state.bookingData.dateOfExperience = payload
  },
  BOOKING_MEET_REQUIREMENT(state, payload) {
    state.isMetRequirement = payload
  },
  BOOKING_ASSET_AVALIABLE(state, payload) {
    state.isAvaliable = payload
  },
  STORE_IS_SUCCESS(state, payload) {
    state.createBookingSuccess = payload
  },
  STORE_FOOTFALL(state, payload) {
    state.bookingData.footfall = {
      min: payload[0],
      max: payload[1],
    }
  },
  removeProfitStock(state) {
    state.createBookingData.profit.isActive = false
    state.createBookingData.profit.stocks = []
  },
  removeProfitProductSold(state) {
    state.createBookingData.product.isStock = false
    state.createBookingData.product.volumeSold = null
  },
  STORE_STOCK(state, payload) {
    state.bookingData.stock = payload
  },
  STORE_STOCKSOLD(state, payload) {
    state.bookingData.stockSold = payload
  },
  INIT_ORDERS(state, payload) {
    state.bookingData.orders = payload
  },
  STORE_ORDERS(state, payload) {
    state.bookingData.orders[payload.index] = {
      name: payload.name,
      amount: payload.amount,
    }
  },
  STORE_WHOLESALER_NAME(state, payload) {
    state.bookingData.wholesaler.name = payload
  },
  STORE_WHOLESALER_EMAIL(state, payload) {
    state.bookingData.wholesaler.email = payload
  },
  STORE_WHOLESALER_PHONE(state, payload) {
    state.bookingData.wholesaler.phone = payload
  },
  STORE_FACILITY(state, payload) {
    state.bookingData.facility = payload
  },
  STORE_ACCESSIBILITY(state, payload) {
    state.bookingData.accessibility = payload
  },
  STORE_SPECIALREQUEST(state, payload) {
    state.bookingData.specialRequest = payload
  },
  STORE_CONTACT_NAME(state, payload) {
    state.bookingData.contact.name = payload
  },
  STORE_CONTACT_PHONE(state, payload) {
    state.bookingData.contact.phone = payload
  },
  UPDATE_PRICE_BREAKDOWN(state, payload) {
    state.createBookingData.campaign.priceBreakdown = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
