import state from './basket.state'
import mutations from './basket.mutations'
import getters from './basket.getters'
import actions from './basket.actions'
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
