<template>
  <span class="Price-Tag">
    {{
      $n(
        convertPrice(),
        enableDecimal ? 'currencyTwoDecimal' : 'currencyZeroDecimal',
        currencyLocale
      )
    }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PriceTag',
  props: {
    price: {
      type: Number,
      default: 0,
    },
    currencyLocale: {
      type: String,
      default: '',
    },
    currencyCode: {
      type: String,
      default: '',
    },
    enableDecimal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('app', ['currencyConvert']),
  },
  methods: {
    convertPrice() {
      const ratio =
        this.currencyConvert[`${this.currencyCode}${this.currencyLocale}`] || 1
      return this.price * ratio
    },
  },
}
</script>

<style lang="scss" scoped>
.Price-Tag {
  @include montserrat-font(16px, $midGray, 'regular');
}
</style>
