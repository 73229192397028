import state from './staff.state'
import getters from './staff.getters'
import actions from './staff.actions'
import mutations from './staff.mutations'

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
