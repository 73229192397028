<template>
  <nav :class="$options.name">
    <div style="">
      <MenuProfile :count="user.group" />
      <ul class="menu">
        <li
          v-show="isAdminRole"
          :class="{
            active:
              $route.name === 'venue profile' ||
              $route.name === 'Venue Group Profile',
            is_venues_group: checkGroup,
          }"
        >
          <RouterLink
            :class="['icon-home', { is_venues_group: checkGroup }]"
            to="#"
          >
            Your venue
          </RouterLink>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import MenuProfile from './component/MenuProfile'

export default {
  name: 'VenueGroupMenu',
  components: { MenuProfile },
  data: () => {
    return {
      pageURL: '',
    }
  },
  computed: {
    ...mapGetters('account', ['isAdminRole', 'userGroup']),
    ...mapState('account', ['user']),
    checkGroup() {
      return this.userGroup == 'venueGroup'
    },
  },
}
</script>

<style lang="scss" scoped src="../_css/sidebarmenu.scss"></style>

<style lang="scss" scoped>
.VenueGroupMenu {
  &.SidebarNav {
    background-color: $primary;
  }
  .SidebarNav {
    width: 305px;
  }
  .menu {
    background-color: $primary;
    a {
      cursor: default;
      font-size: 16px;
    }
  }
}
</style>
