<template>
  <component
    :is="currentPageMenu"
    :class="[$options.name, ipadLandscape, { 'is-open': isNavOpen }]"
  ></component>
</template>

<script>
import VenueGroupMenu from '@/components/sidebar_menu/VenueGroupMenu'

import { mapActions, mapState } from 'vuex'

export default {
  name: 'SidebarNav',
  components: { VenueGroupMenu },
  data: function () {
    return {
      ipadLandscape: '',
    }
  },
  computed: {
    ...mapState('ui', ['isNavOpen']),
    userGroup() {
      const user = this.$store.state.account.user
      return user && user.companies[0] && user.companies[0].type
        ? user.companies[0].type
        : null
    },
    currentPageMenu() {
      return VenueGroupMenu
    },
  },
  watch: {
    $route() {
      this.toggleNav({
        isOpen: false,
      })
    },
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.ipadLandscape = 'ipadLandscape'
    } else if (
      /Macintosh/i.test(navigator.userAgent) &&
      navigator.maxTouchPoints > 1
    ) {
      this.ipadLandscape = 'ipadLandscape'
    }
  },
  methods: {
    ...mapActions('ui', ['toggleNav']),
  },
}
</script>

<style lang="scss" scoped>
.SidebarNav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.is-open {
    display: block !important;
    width: 300px;
    position: fixed;
    top: 50px;
    bottom: initial !important;
    height: auto !important;
    z-index: 10;
    background-color: #fff;
    box-shadow: 0 5px 15px 1px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    @include for-mobile {
      top: 58px;
    }
    @include for-mobile-landscape {
      top: 80px;
    }
    @include for-tablet {
      top: 70px;
    }
  }

  /deep/ .menu {
    padding: 3rem 0;
  }
}
.ipadLandscape {
  top: 30px;
}
</style>
