export default {
  getStaffTypesSuccess: (state, payload) => {
    state.staffType = {
      items: [...payload.getStaffTypes.items],
      count: payload.getStaffTypes.count,
      isLoaded: true,
    }
    state.error = null
  },
  getCompaniesSuccess: (state, payload) => {
    state.companyLists = [...payload.companyMany]
  },
  checkStaffTypeNameSuccess: (state, payload) => {
    state.staffTypeName = { ...payload.checkStaffTypeName }
  },
  checkStaffTypeNameError: (state, payload) => (state.error = payload),
  getCompaniesError: (state, payload) => (state.error = payload),
  getStaffTypesError: (state, payload) => (state.error = payload),
  createStaffTypeSuccess: (state) => (state.error = null),
  createStaffTypeError: (state, payload) => (state.error = payload),
  updateStaffTypeSuccess: (state) => (state.error = null),
  updateStaffTypeError: (state) => (state.error = null),
}
