import { createActionHandlerMutate } from '@/helpers/query'
import { createActionHandlerQuery } from '@/helpers/query'

// actions
export default {
  // CAMPAIGN DASHBOARD
  STORE_CAMPAIGNS(context, payload) {
    context.commit('STORE_CAMPAIGNS', payload)
  },
  STORE_STATUS(context, payload) {
    context.commit('STORE_STATUS', payload)
  },

  // CAMPAIGN CREATION
  RESET_CAMPAIGN_DATA(context) {
    context.commit('RESET_CAMPAIGN_DATA')
  },
  INIT_CAMPAIGN_CREATION(context, payload) {
    context.commit('INIT_CAMPAIGN_CREATION', payload)
  },
  INIT_CAMPAIGN_EDITION(context, payload) {
    context.commit('INIT_CAMPAIGN_EDITION', payload)
  },
  SAVE_TO_STORE(context, payload) {
    context.commit('SAVE_TO_STORE', payload)
  },
  ASSIGN_CAMPAIGN(context, payload) {
    context.commit('ASSIGN_CAMPAIGN', payload)
  },

  SAVE_CURRENT_CAMPAIGN(context, payload) {
    context.commit('SAVE_CURRENT_CAMPAIGN', payload)
  },

  selectBrandIdentifier({ commit }, payload) {
    commit('selectBrandIdentifierSuccess', payload)
  },
  activeRepTask({ commit }, payload) {
    commit('activeRepTask', payload)
  },
  inActiveRepTask({ commit }, payload) {
    commit('inActiveRepTask', payload)
  },

  saveCampaignProfile: createActionHandlerMutate('saveCampaignProfile'),
  getCampaigns: createActionHandlerQuery('getCampaigns'),
}
