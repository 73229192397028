import { dark } from '@/components/ui/styled/theme.js'

export const theme = dark
export const DIAGEO_COMPANY_ID = '5c52b822943ad5000656687d'
export const DIAGEO_IRELAND_COMPANY = '60d98787cc5283002f3b2c58'
export const DIAGEO_GB_COMPANY = '00000' //66fbdf7ba032c7001d7f33e8
export const HHG_AGENCY_COMPANY_ID = '66e427a1ba6cb2001d7d8d0d'
export const COUNTRY_UK = 'cc0000000000000000000001'
export const COUNTRY_IRELAND = 'cc0000000000000000000002'
export const COUNTRY_NORTHERN_IRELAND = 'cc0000000000000000000003'
export const VENUE_HOME_ID = 'edefe0000000000000000027'
export const DEFAULT_CUSTOMER = {
  UK: {
    _id: '5c9df96e96926a6b3ceb1fa1',
    name: "I don't belong to a group",
  },
  Ireland: {
    _id: 'ccc000000000000000000001',
    name: 'All Ireland',
  },
  NorthernIreland: {
    _id: 'ccc000000000000000000002',
    name: 'All Northern Ireland',
  },
}
export const DEFAULT_WHOLESALER = {
  UK: {
    _id: '5c9dde5cbef2d35bffec0e82',
    name: "I can't find my wholesalers",
  },
  Ireland: {
    _id: 'ddd000000000000000000001',
    name: 'All Ireland',
  },
  NorthernIreland: {
    _id: 'ddd000000000000000000002',
    name: 'All Northern Ireland',
  },
}
export const TEMPLATE_VENUE_ALLOCATION =
  'https://s3.eu-west-2.amazonaws.com/static.uat.experiencethis.co/upload_template_csv/template-venue-allocation.csv'
export const TEMPLATE_REP_ALLOCATION =
  'https://s3.eu-west-2.amazonaws.com/static.uat.experiencethis.co/upload_template_csv/template-rep-allocation.csv'
export const TEMPLATE_TERRITORYALLOCATION =
  'https://s3.eu-west-2.amazonaws.com/static.uat.experiencethis.co/upload_template_csv/template_for_territory_allocation.csv'

const DIGITAL_ASSET_TYPE = 'acd000000000000000000011'
const MOBILE_BAR_ASSET_TYPE = 'acd000000000000000000001'
const OUTDOOR_ASSET_TYPE = 'acd000000000000000000009'
export const ASSET = {
  DIGITAL: DIGITAL_ASSET_TYPE,
  MOBILE_BAR: MOBILE_BAR_ASSET_TYPE,
  OUTDOOR: OUTDOOR_ASSET_TYPE,
}

export const ETLOGO =  'https://s3.eu-west-2.amazonaws.com/static.uat.experiencethis.co/logos/main_nav/Logo+-+dark+(5).png'
