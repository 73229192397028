import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
// modules
import account from './account'
import ui from './ui'
import campaign from './campaign'
import experiences from './experiences'
import experience from './experience'
import sponsorshipRequests from './sponsorshipRequests'
import teamManagement from './teamManagement'
import staffManagement from './staffManagement'
import googlePlaces from './googlePlaces'
import budgets from './budgets'
import assets from './assets'
import campaignProfile from './campaignProfile'
import app from './app'
import basket from './basket'

// const debug = process.env.SERVER_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    account,
    ui,
    campaign,
    experience,
    sponsorshipRequests,
    googlePlaces,
    budgets,
    assets,
    teamManagement,
    staffManagement,
    experiences,
    campaignProfile,
    app,
    basket,
  },
  plugins: [
    createPersistedState({
      paths: [
        'account.venueIdentifiers',
        'account.user',
        'account.currentUser',
        'account.bookFor',
        'account.userStates',
        'account.myCountry',
        'account.myCountryDetails',
        'account.venueWaitingReview',
        'account.isSpecificTypeAttatch',
        'campaign.status',
        'campaign.id',
        'campaign',
        'campaignProfile',
        'experience.createBookingData',
        'experience.publicBookingData',
        'experience.isEditBookingMode',
        'experiences.reschedule',
        'basket',
        'ui.popupMyVenues',
      ],
    }),
  ],
  strict: false,
})
