<template>
  <div class="Input" :class="['test', { error: emptyField }]">
    <label v-if="label">{{ label }}</label>
    <textarea
      ref="area"
      :value="message"
      :name="name"
      :class="[{ error: emptyField }]"
      :rows="rows"
      :cols="cols"
      :placeholder="placeholder"
      :maxlength="limit"
      @input="onInput"
    ></textarea>
    <p v-if="limit" class="char-count">{{ charLeft }} characters left</p>
    <div v-show="emptyField" class="messageError">This field is required.</div>
  </div>
</template>

<script>
export default {
  name: "Textarea",
  props: [
    "modelValue",
    "name",
    "label",
    "placeholder",
    "rows",
    "cols",
    "limit",
    "emptyField",
  ],
  computed: {
    message: {
      get() {
        return this.modelValue || ""; // Return value prop or an empty string if not provided
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue); // Emit input event to update parent component
      },
    },
    charLeft() {
      return this.limit - this.message.length;
    },
  },
  methods: {
    onInput(e) {
      if (e.target.value.length > this.limit) return false;
      this.message = e.target.value; // Use the computed property to update the value
    },
    focus() {
      this.$refs.area && this.$refs.area.focus();
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .error {
  border: 1px solid #fb453c;
}
</style>
