export default {
  isFetching: false,
  places: [],
  place: {},
  postcode: '',
  addresses: [],
  countryMany: [],
  countryOne: { postal: { cities: [] } },
  errors: null,
}
