import {
  createActionHandlerQuery,
  // createActionHandlerMutate
} from '@/helpers/query'
export default {
  queryCountryMany: createActionHandlerQuery('queryCountryMany'),
  queryCountryOne: createActionHandlerQuery('queryCountryOne'),
  searchPlaces: async ({ commit }, { apollo, query, variables }) => {
    try {
      const { data: { placeAutocomplete: { predictions } = {} } = {} } =
        await apollo.query({
          query,
          variables,
          debounce: 200,
        })
      commit('savePlacesOnSuccess', predictions)
    } catch (error) {
      commit('savePlacesOnError', {
        error,
        message: 'aciton.searchPlaces error',
      })
    }
  },

  getPlaceDetail: async ({ commit }, { apollo, query, variables }) => {
    try {
      const response = await apollo.query({
        query,
        variables,
        debounce: 500,
      })

      const placeDetail = response.data.placeDetailsRequest.result
      commit('savePlaceDetailOnSuccess', placeDetail)
    } catch (error) {
      commit('savePlaceDetailOnError', {
        error,
        message: 'action.getPlaceDetail error',
      })
    }
  },

  getAddressFromPostCode: async ({ commit }, { apollo, query, variables }) => {
    try {
      const response = await apollo.query({
        query,
        variables,
        debounce: 500,
      })

      if (response.data.getAddressFromPost.postcode) {
        const addresses = response.data.getAddressFromPost.addresses
        const postcode = response.data.getAddressFromPost.postcode
        commit('saveAddressFromPostcodeOnSuccess', { addresses, postcode })
      }
    } catch (error) {
      commit('saveAddressFromPostcodeOnError', {
        error,
        message: 'action.getAddressFromPostcode error',
      })
    }
  },
}
