<template>
  <fieldset :class="$options.name">
    <div
      v-for="(option, index) in options"
      :key="index"
      :class="[option, childClass]"
    >
      <input
        :id="field + index"
        type="radio"
        :name="field"
        :value="option.value"
        :checked="value == option.value"
        @change="onChange"
      />
      <label :for="field + index">
        {{ option.label }}
      </label>
    </div>
  </fieldset>
</template>

<script>
/*
V-Model does not work on this component.
User @change method instead to get velue from this component.
*/
export default {
  name: 'RadioGroup',
  props: ['field', 'options', 'childClass', 'value'], // option: { label: '', value: '' }

  methods: {
    onChange(event) {
      this.$emit('input', event.target.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.RadioGroup {
  border: none;
  padding: 0;

  .option {
    margin: 20px 0;
  }

  label {
    @include sf-font(2rem, rgba(61, 61, 61, 0.5));
  }
}

[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  line-height: 24px;
  display: inline-block;
  color: #666;
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 16px;
  height: 16px;
  background: $secondary;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
</style>
