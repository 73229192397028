/* eslint-disable */
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { withClientState } from 'apollo-link-state'
import { onError } from 'apollo-link-error'
import { ApolloLink, Observable } from 'apollo-link'
import { createApolloProvider } from '@vue/apollo-option'

import service from './config/endpoints'
import {
  clearUserData,
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
} from './helpers/localStorage'
import { renewToken } from './graphql/account/renewToken'



const cache = new InMemoryCache({ addTypename: false })
const stateLink = withClientState({
  cache,
  defaults: {},
})

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getAccessToken()

  operation.setContext({
    headers: {
      accessToken: token ? token : '',
      authorization: token ? token : '',
    },
  })
  return forward(operation)
})

const tryRefreshToken = async () => {
  const refreshToken = getRefreshToken()

  try {
    //Get the email from the Vuex store
    const email = JSON.parse(localStorage.getItem('vuex')).account.user.email

    if (refreshToken !== null && email !== null) {
      let result = await client.mutate({
        mutation: renewToken,
        variables: {
          data: {
            email: email,
            refreshToken: refreshToken,
          },
        },
      })
      if (result) {
        return result
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`[Refresh Error]: ${error}`)
  }
}

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors)
        for (const error of graphQLErrors) {
          const { message, locations, path, extensions } = error
          const { exception } = extensions
          if (
            message.includes(
              'Cannot return null for non-nullable field Account._id'
            )
          ) {
            clearUserData()
            document.location.href = '/account/signin'
          }
          if (
            exception.result &&
            exception.result.name === 'TokenExpiredError'
          ) {
            localStorage.removeItem('accessToken')

            return new Observable((observer) => {
              tryRefreshToken()
                .then((refreshResp) => {
                  if (refreshResp.data.renewToken.error) {
                    clearUserData()
                    document.location.href = '/account/signin'
                  } else {
                    setRefreshToken(refreshResp.data.renewToken.refreshToken)
                    setAccessToken(refreshResp.data.renewToken.accessToken)

                    operation.setContext(({ headers = {} }) => ({
                      headers: {
                        // Re-add old headers
                        ...headers,
                        // Switch out old access token for new one
                        accessToken: refreshResp.data.renewToken.accessToken,
                        authorization: refreshResp.data.renewToken.accessToken,
                      },
                    }))
                  }
                })
                .then(() => {
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  }

                  // Retry last failed request
                  forward(operation).subscribe(subscriber)
                })
                .catch((error) => {
                  // eslint-disable-next-line no-console
                  console.log(`[Refresh Error]: ${error}`)
                  clearUserData()
                  document.location.href = '/account/signin'
                })
            })
          } else if (exception.errors && exception.errors.length !== 0) {
            // if (message.includes('connect ECONNREFUSED')) {
            if (message.includes('socket hang up')) {
              return new Observable((observer) => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                }
                // Retry api request after 1 second
                setTimeout(() => {
                  forward(operation).subscribe(subscriber)
                }, 1000)
              })
            }
          } else if (exception.errors && exception.errors.length !== 0) {
            // Detection INTERNAL_SERVER_ERROR
            // eslint-disable-next-line no-console
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
                locations
              )}, Path: ${JSON.stringify(path)}`
            )
          } else {
            // eslint-disable-next-line no-console
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
                locations
              )}, Path: ${JSON.stringify(path)}`
            )
          }
        }

      if (networkError) {
        if (networkError.statusCode === 401) {
          clearUserData()
          document.location.href = '/account/signin'
        }
      }
    }),
    authMiddleware,
    stateLink,
    new HttpLink({
      uri: service.gateway,
      credentials: 'same-origin',
    }),
  ]),
  cache,
  connectToDevTools: true,
})

export const apolloProvider = createApolloProvider({
  defaultClient: client,
  defaultOptions: {
    $query: {
      fetchPolicy: 'cache-and-network',
    },
  },
  errorHandler(error) {
    // eslint-disable-next-line no-console
    console.log(
      '%cError',
      'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
      error
    )
  },
})