export default {
  pendingList: {
    count: 0,
    items: [],
    isLoaded: false,
  },
  confirmedList: {
    count: 0,
    items: [],
    isLoaded: false,
  },
  rejectedList: {
    count: 0,
    items: [],
    isLoaded: false,
  },
  requestDetail: {},
  requestCreate: {},
  requestReject: {},
  error: null,
}
