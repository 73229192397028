<template>
  <nav :class="[$options.name, {'mobile-style': isMobile}]">
    <ul class="menu sidebar-brand">
      <li :class="{ active: $route.name == 'welcome' }">
        <RouterLink class="icon-Home-converted" to="/welcome">Home</RouterLink>
      </li>
      <li
        :class="{
          active: ['list venue', 'my venue', 'campaign marketplace'].includes(
            $route.name,
          ),
        }"
      >
        <RouterLink class="icon-map-pin-converted" :to="venueLink">
          Book
        </RouterLink>
      </li>
      <!-- <li
          v-if="isDataAnalytic"
          :class="{ active: $route.name == 'DataAnalytics' }"
        >
          <RouterLink class="icon-Chart" to="/data-analytics">
            Data Analytics
          </RouterLink>
        </li> -->
      <li class="horizontal">
        <div class="section">Manage</div>
        <div class="hr"></div>
      </li>

      <li
        :class="{
          active:
            $route.name == 'campaign dashboard' ||
            $route.name == 'DataAnalytics',
        }"
      >
        <RouterLink class="icon-grid" to="/campaign/dashboard">
          Campaigns and targets
        </RouterLink>
      </li>
      <ul v-if="$route.path && $route.path.includes('/data-analytics')">
        <li class="sub-menu-campaign-list">
          <div class="icon-Chart sub-menu-campaign" to="/data-analytics">
            Data Analytics
          </div>
        </li>
      </ul>
      <li :class="{ active: sponsorshipPages.includes($route.name) }">
        <RouterLink class="icon-Alarm" to="/requests">
          Booking requests
        </RouterLink>
      </li>
      <li
        v-show="isAdminRole"
        :class="{ active: $route.name == 'Booking Requests' }"
      >
        <RouterLink class="icon-Calendar" to="/requests/booking">
          Facilitator requests
        </RouterLink>
      </li>
      <li :class="{ active: $route.name == 'calendar' }">
        <RouterLink class="icon-calander" to="/calendar">Calendar</RouterLink>
      </li>
      <li class="horizontal">
        <div>Measure</div>
        <div class="hr"></div>
      </li>
      <li :class="{ active: reviewPages.includes($route.name) }">
        <RouterLink class="icon-Favorite" to="/management/rate-and-review">
          Rate and Review
        </RouterLink>
      </li>
      <li :class="{ active: $route.name == 'SalesProgress' }">
        <RouterLink class="icon-cup-2" to="/sales-progress">
          Leaderboard
        </RouterLink>
      </li>
      <li v-show="isAdminRole || isGeneralRole" class="horizontal">
        <div class="section">Inventory</div>
        <div class="hr"></div>
      </li>
      <li
        v-show="isAdminRole"
        :class="{ active: $route.name == 'asset inventory' }"
      >
        <RouterLink class="icon-check-list-checkmark" to="/management/asset">
          Assets
        </RouterLink>
      </li>
      <li
        v-show="isAdminRole"
        :class="{ active: $route.name == 'staff management' }"
      >
        <RouterLink class="icon-Group" to="/management/staff">Staff</RouterLink>
      </li>
      <li
        v-show="isAdminRole"
        :class="{ active: $route.name == 'stock inventory' }"
      >
        <RouterLink class="icon-bullet-list-text" to="/management/stock">
          Stock
        </RouterLink>
      </li>
      <li
        v-show="isAdminRole || isGeneralRole"
        :class="{ active: $route.name == 'management budget' }"
      >
        <RouterLink class="icon-Money" to="/management/budget">
          Budgets
        </RouterLink>
      </li>
      <li class="horizontal">
        <div class="section">User management</div>
        <div class="hr"></div>
      </li>
      <li
        :class="[{ active: $route.name == 'team management' }, 'buttom-space']"
      >
        <RouterLink class="icon-Group1" to="/management/team">Team</RouterLink>
      </li>
      <!-- <li class="sub-menu">
          Management
          <ul>
         
          </ul>
        </li> -->
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import { COUNTRY_IRELAND } from '../../config/constants'
import { isMobile } from '@/helpers/checkIsMobileDevice'

export default {
  name: 'BrandMenu',
  data() {
    return {
      isDataAnalytic: false,
      reviewPages: ['review page', 'rate and review'],
      sponsorshipPages: ['Booking Details', 'Sponsorship Requests'],
      isMobile: isMobile()
    }
  },
  computed: {
    ...mapGetters('account', [
      'isAdminRole',
      'isGeneralRole',
      'getMyCountry',
      'getCompany',
      'getBrandSetting',
    ]),
    isGeneralIreland() {
      return this.isGeneralRole && this.getMyCountry._id === COUNTRY_IRELAND
    },
    venueLink() {
      return this.getBrandSetting.masterFile ? '/my-venues' : '/venues'
    },
  },
  mounted() {
    if (typeof window !== undefined || typeof window !== 'undefined') {
      window.openAnalytic = () => {
        this.openDataAnalytic()
      }
    }
  },
  methods: {
    openDataAnalytic() {
      this.isDataAnalytic = true
    },
  },
}
</script>

<style lang="scss" scoped src="../_css/sidebarmenu.scss"></style>

<style lang="scss" scoped>
@media (min-width: 1025px) and (max-width: 1439px) {
  .mobile-style a {
    font-size: clamp(10px, 0.5vw, 12px) !important;
  }
}
.BrandMenu {
  overflow-y: hidden !important;
  .horizontal {
    padding: 12px 10px;
    /* padding-left: 10px; */
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* @include sf-font(1em, $shade3); */
    @include montserrat-font(12px, $shade3);

    .section {
      width: auto;
    }
    .hr {
      margin-left: 10px;
      display: inline-block;
      width: auto;
      flex-grow: 1;
      border: 1px solid $shade3;
    }
  }
  &.SidebarNav {
    background-color: $primary;
  }
  .brand-logo {
    padding: 50px 36px 0;

    .logo {
      width: 40px;
      height: 40px;
      border-radius: 11px;
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.16);
    }

    .brand-select {
      @include sf-font(1.4rem, #3d3d3d);
      background-color: #ffffff;
      border: none;
      margin-left: 0.75rem;
      padding: 0.75rem;
    }
  }

  .menu {
    .counter {
      @include sf-font(1.4rem, #ffffff);
      background-color: rgb(49, 48, 73);
      border-radius: 9px;
      padding: 2px 6px;
      margin-left: 1.5rem;
    }
  }
  .sidebar-brand {
    // border-top: 1px solid white;
  }

  .sub-menu-campaign-list {
    padding: 1.5rem 0 0 1.5rem;
    color: $quaternary;
  }

  .sub-menu-campaign {
    &::before {
      margin-right: 0.5rem;
    }
  }
  .buttom-space {
    padding-bottom: 30px;
  }
}
</style>
