<template>
  <div class="template-sidebar">
    <SidebarNav></SidebarNav>

    <div class="main">
      <div class="container-fluid">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarNav from '@/components/SidebarNav'

export default {
  name: 'TemplateLeftSidebar',
  components: { SidebarNav },
}
</script>

<style lang="scss" scoped>
.template-sidebar {
  .SidebarNav {
    left: 0;
    padding-top: 80px;
    @include for-mobile-to-tablet {
      display: none;
      padding-top: 0;
    }
  }
  .main {
    @include for-mobile-to-tablet {
      margin: 12rem auto 0;
    }
  }
}
</style>
