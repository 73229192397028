import styled, { css, keyframes } from 'vue3-styled-components'

const popUpAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

const AlignSelect = (align) => {
  switch (align) {
    case 'left':
      return 'left: 0;'
    case 'right':
      return 'right: 0;'
    default:
      return 'left: 0;' // Default alignment to left
  }
}

export const Container = styled.div`
  ${({
    width = 301,
    theme: { shades },
    mt = 50,
    align = 'left',
    mobile,
  }) => css`
    width: ${width}px;
    border: 1px solid ${shades[1]};
    box-shadow: 0px 4px 4px -1px #0c0c0d0d;
    box-shadow: 0px 4px 4px -1px #0c0c0d1a;
    padding: 10px;
    border-radius: 8px;
    position: absolute;
    top: 0;
    margin-top: ${mt}px;
    background-color: white;
    z-index: 999;
    ${AlignSelect(align)};
    animation: ${popUpAnimation} 0.3s ease-out;
    ${mobile
      ? `
      margin-right: -41px;
       border-radius: 0px;
        @media only screen and (max-width: 500px) {
      width: 100%;
      margin-right:0;
      height:  100%;
      position: fixed;
      margin-top: 80px;
      overflow: hidden;
      overflow-y: auto;
      border-radius: 0px;
      padding-bottom: 150px;
    }
    
    `
      : ``}
  `}
`

export const ContainerMobile = styled.div`
  ${({
    width = 301,
    theme: { shades },
    mt = 50,
    align = 'left',
    mobile,
    ipad,
  }) => css`
    width: ${width}px;
    border: 1px solid ${shades[1]};
    box-shadow: 0px 4px 4px -1px #0c0c0d0d;
    box-shadow: 0px 4px 4px -1px #0c0c0d1a;
    padding: 10px;
    border-radius: 8px;
    position: absolute;
    top: 0;
    
    ${ipad
      ? ` 
       max-height: 80vh;
    overflow: hidden;
    overflow-y: auto;
    `
      : ''}

    margin-top: ${mt}px;
    background-color: white;
    z-index: 999;
    ${AlignSelect(align)};
    animation: ${popUpAnimation} 0.3s ease-out;
    ${mobile
      ? `
      margin-right: -41px;
       border-radius: 0px;
        @media only screen and (max-width: 500px) {
      width: 100%;
      margin-right:0;
      height:  100%;
      position: fixed;
      margin-top: 80px;
      overflow: hidden;
      overflow-y: auto;
      border-radius: 0px;
      padding-bottom: 150px;
    }
    
    `
      : ``}
  `}
`

export const Logo = styled.img`
  height: 20px;
  width: 20px;
`

export const Option = styled.div`
  ${({ theme: { shades }, active }) => css`
    display: flex;
    justify-content: space-between;
    height: 44px;
    align-items: center;
    position: relative;
    padding-left: 10px;
    ${active
      ? `
       background: ${shades[17]};
             border-radius: 8px;
    `
      : ''}
    cursor: pointer;
    &:hover {
      background: ${shades[17]};
      border-radius: 8px;
    }
  `}
`
export const OptionNameWrapper = styled.div`
  display: flex;
  gap: 1rem;
  .icon {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
`

export const WrapperHeader = styled.div`
  width: 100%;
`

export const Header = styled.div`
  width: 100%;
  text-align: start;
  color: #707070;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const OptionCount = styled.div`
  ${({ theme: { notiAlert } }) => css`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${notiAlert};
    color: white;
    font-size: 10px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  `}
`

export const GroupContainer = styled.div`
  text-align: start;
  width: 100%;

  .Divider {
    margin: 10px 0%;
  }
`

export const GroupHeader = styled.div`
  padding: 1px 0;
`
export const OptionMobileWrapper = styled.div`
  width: 100%;
`

export const OptionMobile = styled.div`
  ${({ theme: { shades }, active }) => css`
    display: flex;

    height: 44px;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
    ${active
      ? `
       background: ${shades[17]};
             border-radius: 8px;
    `
      : ''}
    &:hover {
      background: ${shades[17]};
      border-radius: 8px;
    }
  `}
`
