export default {
  budgetList: {
    items: [],
    count: 0,
    isLoaded: false,
  },
  budgetAdd: {},
  budgetUpdate: {},
  budgetEdit: {},
  poCodeList: [],
  emailList: {
    items: [],
    count: 0,
    isLoaded: false,
  },
  emailSend: {},
  error: null,
  getEmailError: null,
  staffAgencyActive: false,
}
