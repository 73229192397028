export default {
  getBookingSuccess: (state, payload) => {
    state.bookingList = { ...payload, isLoaded: true }
  },
  getBookingError: (state, payload) => (state.error = payload),
  getAssetListSuccess: (state, payload) => {
    state.assetList = { ...payload, isLoaded: true }
  },
  getAssetListError: (state, payload) => (state.assetError = payload),
  returningAssetTrackSuccess: () => {},
  returningAssetTrackError: (state, payload) => (state.assetError = payload),
  getAssetInventoryListSuccess: (state, payload) => {
    state.assetInventoryList = { ...payload, isLoaded: true }
  },
  getAssetInventoryListError: (state, error) => (state.error = error),
  getKeyContactNamesSuccess: (state, payload) => {
    state.keyContactNames = { ...payload.getTeamMember, isLoaded: true }
  },
  getKeyContactNamesError: (state, payload) => (state.error = payload),
}
