<template>
  <nav :class="$options.name">
    <ul class="menu sidebar-agency">
      <li class="horizontal">
        <div class="section">Manage</div>
        <div class="hr"></div>
      </li>
      <li :class="{ active: $route.name == 'campaign dashboard' }">
        <RouterLink class="icon-grid" to="/campaign/dashboard">
          Campaigns
        </RouterLink>
      </li>
      <li :class="{ active: $route.name == 'Booking Requests' }">
        <RouterLink class="icon-Calendar" to="/requests/booking">
          Booking requests
        </RouterLink>
      </li>

      <li :class="{ active: $route.name == 'calendar' }">
        <RouterLink class="icon-calander" to="/calendar">Calendar</RouterLink>
      </li>

      <li class="horizontal">
        <div class="section">Measure</div>
        <div class="hr"></div>
      </li>
      <li :class="{ active: reviewPages.includes($route.name) }">
        <RouterLink class="icon-Favorite" to="/management/rate-and-review">
          Rate and Review
        </RouterLink>
      </li>
      <li class="horizontal">
        <div class="section">Inventory</div>
        <div class="hr"></div>
      </li>
      <li :class="{ active: $route.name == 'asset inventory' }">
        <RouterLink class="icon-check-list-checkmark" to="/management/asset">
          Assets
        </RouterLink>
      </li>
      <li
        v-show="isAdminRole"
        :class="{ active: $route.name == 'staff management' }"
      >
        <RouterLink class="icon-Group" to="/management/staff">
          Staff
        </RouterLink>
      </li>
      <li v-show="isAdminRole" class="horizontal">
        <div class="section">My agency</div>
        <div class="hr"></div>
      </li>
      <li
        v-show="isAdminRole"
        :class="{ active: $route.name == 'team management' }"
      >
        <RouterLink class="icon-Group1" to="/management/team">Team</RouterLink>
      </li>

      <li
        v-show="isAdminRole"
        :class="{ active: $route.name === 'agency profile' }"
      >
        <RouterLink class="icon-Settings" to="/agency/profile">
          Agency settings
        </RouterLink>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AgencyMenu',
  data() {
    return {
      reviewPages: ['review page', 'rate and review'],
    }
  },
  computed: {
    ...mapGetters('account', ['isAdminRole']),
  },
}
</script>

<style lang="scss" scoped src="../_css/sidebarmenu.scss"></style>

<style lang="scss" scoped>
.AgencyMenu {
  .horizontal {
    padding: 12px 10px;
    /* padding-left: 10px; */
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* @include sf-font(1em, $shade3); */
    @include montserrat-font(12px, $shade3);

    .section {
      width: auto;
    }
    .hr {
      margin-left: 10px;
      display: inline-block;
      width: auto;
      flex-grow: 1;
      border: 1px solid $shade3;
    }
  }
  &.SidebarNav {
    background-color: $primary;
  }
}
.brand-logo {
  padding: 50px 36px 0;

  .logo {
    width: 40px;
    height: 40px;
    border-radius: 11px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.16);
  }

  .brand-select {
    @include sf-font(1.4rem, #3d3d3d);
    background-color: #ffffff;
    border: none;
    margin-left: 0.75rem;
    padding: 0.75rem;
  }
}

.menu {
  flex: 1 0 auto;

  .counter {
    @include sf-font(1.4rem, #ffffff);
    background-color: #a3a0fb;
    border-radius: 9px;
    padding: 2px 6px;
    margin-left: 1.5rem;
  }
}
</style>
