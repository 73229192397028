<template>
  <TableGrid
    :columns="columns"
    :custom="custom"
    :type="type"
    :gap="gap"
    :no-padding="noPadding"
    :columns-mobile="columnsMobile"
    :custom-mobile="customMobile"
  >
    <slot />
  </TableGrid>
</template>

<script>
import * as components from './styles'

export default {
  name: 'TableGridStyle',
  components: { ...components },
  props: {
    columns: {
      type: Number,
      default: null,
    },
    columnsMobile: {
      type: Number,
      default: null,
    },
    gap: {
      type: Number,
      default: 0,
    },
    custom: {
      type: String,
      default: '',
    },
    customMobile: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
