import styled from 'vue3-styled-components'
const textProps = {
  disabled: {
    type: Boolean,
    default: false,
  },
  inline: {
    type: Boolean,
    default: false,
  },
  isBold: {
    type: Boolean,
    default: false,
  },
}
/**
 * Header styles
 */
/**
 * Font 28px
 */
export const ExtraHeaderText = styled('div')`
  ${({
  theme: {
    headerText,
    primary,
    font: { sizes },
  },
}) => `
    ${headerText(primary, sizes.heading[10])}
    padding-bottom: 20px;

    &.center {
      text-align: center; 
    }

    &.no-margin-bottom {
      padding-bottom: 0;
    }
  `}
`
/**
 * Font 20px
 * */
export const HeaderText = styled('div', textProps)`
  ${({
  disabled,
  theme: {
    primary,
    text,
    headerText,
    font: { sizes },
  },
}) => `
    ${headerText(primary, sizes.heading[9])}
    padding-bottom: 20px;
    color: ${disabled ? text.placeholder : primary};
    
    &.black-text {
      color: #000000;
    }

    &.no-margin-bottom {
      padding-bottom: 0;
    }
  `}
`
export const HeaderTextSemiBold = styled('div', textProps)`
  ${({
  disabled,
  theme: {
    primary,
    text,
    fontInter,
    font: { sizes },
  },
}) => `
    ${fontInter(sizes.heading[9], primary, 'bold')}
    padding-bottom: 20px;
    color: ${disabled ? text.placeholder : primary};
    
    &.no-margin-bottom {
      padding-bottom: 0;
    }
  `}
`
export const BoldText = styled('div', textProps)`
  ${({ theme: { primary, headerText }, inline }) => `
    ${headerText(primary, '16px')}
    padding-bottom: 10px;
    &.white-text {
      color: #ffffff;
    }
    ${inline ? 'display: inline-block;' : ''}
  `}
`
export const BoldSmall = styled('div')`
  ${({ theme: { primary, headerText } }) => `
    ${headerText(primary, '14px')}
    padding-bottom: 10px;
    &.white-text {
      color: #ffffff;
    }
    &.black-text {
      color: #000000;
    }

    &.no-margin-bottom {
      padding-bottom: 0;
    }
  `}
`
/**
 * Text styles
 */
/**
 * Font 16px; Mid Gray
 * */
export const NormalText = styled('div', textProps)`
  ${({ theme: { normalText, midGray }, isBold, inline }) => `
    ${normalText(midGray, '16px')}
    margin-bottom: 10px;
    &.white-text {
      color: #ffffff;
    }
    &.no-margin-bottom {
      margin-bottom: 0;
    }
    ${isBold ? 'font-weight: 700;' : ''}
    ${inline ? 'display: inline-block;' : ''}
  `}
`
/**
 * Font 14px; Mid Gray
 * */
export const NormalTextSmall = styled('div')`
  ${({ theme: { normalText, midGray } }) => `
    ${normalText(midGray, '14px')}
    margin-bottom: 10px;
    &.white-text {
      color: #ffffff;
    }
    &.black-text {
      color: #000000;
    }
    &.no-margin-bottom {
      margin-bottom: 0;
    }
  `}
`
/**
 * Font 10px; Mid Gray
 * */
export const NormalTextTiny = styled('div')`
  ${({ theme: { normalText, midGray } }) => `
    ${normalText(midGray, '10px')}
  `}
`

/**
 * Font 10px; Mid Gray
 * */
export const NormalTextTinySecondary = styled('div')`
  ${({ theme: { normalText, midGray } }) => `
    ${normalText(midGray, '12px')}
    &.white-text {
      color: #ffffff;
    }

     &.black-text {
      color: #000000;
    }
  `}
`


/**
 * Font 16px; Mid Gray
 * */

export const PrimaryText = styled('div')`
  ${({ theme: { normalText, primary } }) => `
    ${normalText(primary, '16px')}
    margin-bottom: 10px;

    &.no-margin-bottom {
      margin-bottom: 0;
    }
  `}
`

export const PrimaryTextSmall = styled('div')`
  ${({ theme: { normalText, primary } }) => `
    ${normalText(primary, '14px')}
    margin-bottom: 10px;

    &.black-text {
      color: #000000;
    }

    &.no-margin-bottom {
      margin-bottom: 0;
    }
  `}
`

export const ErrorText = styled('div')`
  ${({ theme: { normalText, danger } }) => `
    ${normalText(danger, '14px')}
  `}
`

export const ActionText = styled('div')`
  ${({ theme: { normalText, chart: {  block: { tertiary } } } }) => `
    ${normalText(tertiary, '16px')};
  `}
`
