<template>
  <div class="template-no-sidebar">
    <div class="main">
      <div class="container-fluid">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyPage',
}
</script>

<style lang="scss" scoped>
// Fix for space footer when page loading.
.main {
  min-height: calc(100vh - 290px);
}

.template-no-sidebar {
  margin-top: 12rem;
}
</style>
