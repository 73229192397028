export default {
  getBudgetsSuccess: (state, payload) => {
    state.budgetList = {
      items: [...payload.items],
      count: payload.count,
      isLoaded: true,
    }
  },
  getBudgetsError: (state) => (state.error = null),
  addBudgetSuccess: (state, payload) => {
    state.budgetAdd = { ...payload, added: true }
  },
  addBudgetError: (state, payload) => {
    state.error = payload
    state.budgetAdd = { added: false }
  },
  addBudgetClear: (state) => {
    state.budgetAdd = { added: false }
  },
  updateBudgetSuccess: (state, payload) => {
    state.budgetUpdate = { ...payload, updated: true }
  },
  updateBudgetError: (state, payload) => {
    state.error = payload
    state.budgetUpdate = { updated: false }
  },
  editBudgetSuccess: (state, payload) => {
    state.budgetEdit = { ...payload, edited: true }
  },
  editBudgetError: (state, payload) => {
    state.error = payload
    state.budgetEdit = { edited: false }
  },
  getPOCodeSuccess: (state, payload) => {
    state.poCodeList = [...payload.purchaseOrderAssignmentByIds]
  },
  getPOCodeError: (state, payload) => (state.error = payload),
  clearPOCode: (state) => {
    state.poCodeList = []
  },
  getEmailListSuccess: (state, payload) => {
    state.emailList = {
      items: [...payload.items],
      count: payload.count,
      isLoaded: true,
    }
  },
  getEmailListError: (state, payload) => (state.getEmailError = payload),
  sendEmailSuccess: (state, payload) => {
    state.emailSend = { ...payload, isSend: true }
  },
  sendEmailError: (state) => (state.emailSend = { isSend: false }),
  setKeyword: (state, payload) => {
    state.staffAgencyActive = payload === 'staffAgency'
  },
  clearError: (state) => (state.error = null),
}
