<template>
  <nav :class="$options.name">
    <div class="wrap-menue">
      <div v-show="userGroup == 'venueGroup'" class="menu-venue-group">
        <MenuProfile />
        <div class="menu-venue-group-back">
          <i class="icon-cheveron-left"></i>
          <RouterLink :to="groupProfilePageURL"> Back to venues </RouterLink>
        </div>
      </div>
      <ul class="menu side-venue-group">
        <li
          v-show="isAdminRole"
          :class="{
            active: $route.name == 'campaign marketplace',
            is_venues_group: checkGroup,
          }"
        >
          <RouterLink
            :class="['icon-marketplace', { is_venues_group: checkGroup }]"
            to="/marketplace"
          >
            Marketplace
          </RouterLink>
        </li>
        <li
          :class="{
            active:
              $route.name == 'experience timeline' ||
              $route.name == 'experience view' ||
              $route.name == 'experience dashboard',
            is_venues_group: checkGroup,
          }"
        >
          <RouterLink
            :class="['icon-events', { is_venues_group: checkGroup }]"
            to="/experience/dashboard"
          >
            Your Bookings
          </RouterLink>
        </li>
        <!--Temporarily hide this EXP-3590-->
        <!-- <li
            v-show="isAdminRole"
            :class="{
              active:
                $route.name === 'venue profile' ||
                $route.name === 'Venue Group Profile',
              is_venues_group: checkGroup
            }"
          >
            <RouterLink
              v-if="true"
              :class="['icon-venue', { is_venues_group: checkGroup }]"
              :to="pageURL"
            >
              Your venue
            </RouterLink>
          </li> -->
        <li
          :class="{
            active: $route.name === 'team management',
            is_venues_group: checkGroup,
          }"
        >
          <RouterLink
            :class="['icon-team', { is_venues_group: checkGroup }]"
            to="/management/team"
          >
            Team
          </RouterLink>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import CONSTANTS from '../../config/url'
import MenuProfile from './component/MenuProfile'

export default {
  name: 'VenueMenu',
  components: { MenuProfile },
  data: () => {
    return {
      pageURL: CONSTANTS.VENUES.PROFILE,
      groupProfilePageURL: CONSTANTS.VENUES.GROUP_PROFILE,
    }
  },
  computed: {
    ...mapGetters('account', ['isAdminRole', 'userGroup', 'isHomeUser']),
    checkGroup() {
      return this.userGroup == 'venueGroup'
    },
    isAdminAndNotHomeUser() {
      return this.isAdminRole && !this.isHomeUser
    },
  },
}
</script>

<style lang="scss" scoped src="../_css/sidebarmenu.scss"></style>

<style lang="scss" scoped>
.VenueMenu {
  .side-venue-group {
    // background-color: white !important;
    height: auto !important;
    min-height: auto !important;
  }
  &.SidebarNav {
    background-color: $primary;
    justify-content: flex-start;
  }
  .menu {
    flex: 0 0 auto;
    padding: 0;
    background-color: $primary;
    min-height: 100%;
    height: 100%;
    &-venue-group {
      &-back {
        @include sf-font(1.6rem, $gray, 'semibold');
        padding: 1rem 3.3rem 0rem 3.3rem;

        a {
          color: $gray;
          @include montserrat-font(14px, $white, 'regular');
          text-decoration: none;
          &:hover {
            @include montserrat-font(14px, $shade2, 'regular');
          }
        }
      }
    }
  }
  .menu-venue-group-back {
    margin-top: 44px;
  }
  .wrap-menue {
    height: calc(100vh - 80px);
    background-color: $primary;
  }
}
</style>
