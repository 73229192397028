// import * as constant from '../mutation-constant'

const createActionBasket =
  (mutationName) =>
  ({ commit }, detail) => {
    commit(`${mutationName}Success`, detail)
  }

export default {
  addBasket: createActionBasket('addBasket'),
  getBasket: createActionBasket('getBasket'),
  removeBasket: createActionBasket('removeBasket'),
  removeAllBasket: createActionBasket('removeAllBasket'),
}
