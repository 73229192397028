import { debounce } from 'lodash'
/**
 * createActionHandler : Higher order function to return a new function.
 * @param {String} mutationName - Name of mutation to be commit.
 * @param {String} responseKey - Key from response
 */
export const createActionHandlerQuery = (mutationName, responseKey) =>
  debounce(
    async (
      { commit },
      { apollo, query, variables, callbackFn, fetchPolicy }
    ) => {
      try {
        const payload = {
          query,
          variables,
        }

        if (fetchPolicy) {
          payload.fetchPolicy = fetchPolicy
        }

        const response = await apollo.query(payload)

        const responseData = responseKey
          ? response.data[responseKey]
          : response.data

        commit(`${mutationName}Success`, responseData)
        if (callbackFn) callbackFn()
        return new Promise((resolve) => resolve(responseData))
      } catch (error) {
        commit(`${mutationName}Error`, {
          message: `${mutationName} error`,
          error,
        })
      }
    },
    300,
    { leading: true }
  )

export const createActionHandlerMutate = (mutationName, responseKey) =>
  debounce(
    async ({ commit }, { apollo, mutation, variables, fetchPolicy }) => {
      try {
        const payload = {
          mutation,
          variables,
        }

        if (fetchPolicy) {
          payload.fetchPolicy = fetchPolicy
        }

        const response = await apollo.mutate(payload)

        const responseData = responseKey
          ? response.data[responseKey]
          : response.data

        commit(`${mutationName}Success`, responseData)
        return new Promise((resolve) => resolve(responseData))
      } catch (error) {
        commit(`${mutationName}Error`, {
          message: `${mutationName} error`,
          error,
        })
      }
    },
    500,
    { leading: true }
  )
