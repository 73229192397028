<template>
  <div class="Page-Loader -modal" :class="{ background: backgroundActive }">
    <div class="Page-Loader_wrapper">
      <font-awesome-icon v-show="!hideSpinner" icon="spinner" size="4x" pulse />
      <div
        v-if="detailLoader1 !== '' && detailLoader2 !== ''"
        class="Page-Loader_detail"
      >
        {{ detailLoader1 }}
        <br />
        {{ detailLoader2 }}
      </div>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faSpinner)

export default {
  name: 'PageLoader',
  components: { FontAwesomeIcon },
  props: {
    detailLoader1: {
      type: String,
      default: '',
    },
    detailLoader2: {
      type: String,
      default: '',
    },
    hideSpinner: {
      type: Boolean,
      default: false
    },
    backgroundActive: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.Page-Loader.-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  &.background {
    background-color: rgba(21, 21, 21, 0.6);
    z-index: 9999;
  }
}

.Page-Loader_wrapper {
  color: $primary;
  display: flex;
  z-index: 100;

  align-items: center;
  flex-direction: column;
}
.Page-Loader_detail {
  text-align: center;
  flex-direction: row;
  width: 100%;
  margin-top: 2rem;
  color: $gray-light;
  font-weight: bold;
}
</style>
