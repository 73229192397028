import {
  createActionHandlerQuery,
  createActionHandlerMutate,
} from '@/helpers/query'

export default {
  getTeamLists: createActionHandlerQuery('setTeamMembers', 'getTeamMember'),
  getTeamPoLists: createActionHandlerQuery('getTeamMemberPo'),
  getTeamSum: createActionHandlerQuery('getTeamMemberSum'),
  addTeamMembers: createActionHandlerMutate('addTeamMembers'),
}
