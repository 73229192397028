/* eslint-disable no-empty */
import CONSTANTS from '../config/url'
import { DIAGEO_COMPANY_ID } from '../config/constants'
export const isPermitToBooking = to => {
  let path = to.matched[0].path
  // return path !== ['account/signin'].includes(path)
  return (
    path === '/marketplace' ||
    path === '/basket' ||
    path === '/basket/delivery' ||
    path === '/basket/checkout' ||
    path === '/basket/thankyou' ||
    path === '/campaign/profile/:slug' ||
    path === '/experience/book/:id' ||
    path === '/experience/book/:id/payment' ||
    path === '/experience/book/:id/result' ||
    path === '/experience/timeline/:id'
  )
}
export const showBookForIcon = (to, role) => {
  const path = to.matched[0].path
  const PATH_BOOKFOR_BRAND = [
    '/marketplace',
    '/basket',
    '/basket/delivery',
    '/basket/delivery1',
    '/basket/thankyou',
    '/marketplace/subtheme/:id',
    '/campaign/profile/:slug',
    '/experience/book/:id',
    '/experience/book/:id/payment',
    '/experience/book/:id/result',
    '/experience/timeline/:id',
    '/campaign/profile/:slug/:bookforId?',
    '/campaign/preview/:slug'
  ]
  const PATH_BOOKFOR_VENUE_GROUP = [
    '/marketplace',
    '/basket',
    '/basket/checkout',
    '/basket/delivery',
    '/basket/thankyou',
    '/marketplace/subtheme/:id',
    '/campaign/profile/:slug',
    '/experience/book/:id',
    '/experience/book/:id/payment',
    '/experience/book/:id/result',
    '/experience/timeline/:id',
    '/experience/dashboard',
    '/management/team',
    '/venue/profile',
    '/experience/preview/:bookingId',
    '/campaign/profile/:slug/:bookforId?'
  ]
  let bookForPath = []
  if (role === 'brand') {
    bookForPath = PATH_BOOKFOR_BRAND
  } else if (role === 'venueGroup') {
    bookForPath = PATH_BOOKFOR_VENUE_GROUP
  }
  return bookForPath.includes(path)
}
export const isAccessBookFor = (to, role) => {
  const path = to.matched[0].path

  const PATH_BOOKFOR_BRAND = ['/account/signin']
  const PATH_BOOKFOR_VENUE_GROUP = ['/account/signin']
  let bookForPath = []
  if (role === 'brand') {
    bookForPath = PATH_BOOKFOR_BRAND
  } else if (role === 'venueGroup') {
    bookForPath = PATH_BOOKFOR_VENUE_GROUP
  }
  return !bookForPath.includes(path)
}
export const handleRedirectUrl = ({ type, role, isOnboarding, companyId }) => { // eslint-disable-line
  let routerUrl = ''
  if (type === 'venue' && isOnboarding && role === 'admin') {
    routerUrl = CONSTANTS.WELCOME
  } else if (type === 'venue' && isOnboarding && role === 'general') {
    routerUrl = CONSTANTS.WELCOME
  } else if (type === 'venue') {
    routerUrl = CONSTANTS.WELCOME
  } else if (type === 'brand' && role === 'admin') {
    routerUrl = CONSTANTS.WELCOME
  } else if (type === 'brand' && role === 'general') {
    if (DIAGEO_COMPANY_ID === companyId) {
      routerUrl = CONSTANTS.WELCOME
    } else {
      routerUrl = CONSTANTS.WELCOME
    }
  } else if (type === 'venueGroup') {
    routerUrl = CONSTANTS.WELCOME
  } else {
    routerUrl = CONSTANTS.BOOKING_REQUEST.LIST
  }
  return routerUrl
}

export const handleRateReviewUrl = reviews => {
  return reviews.map(item => {
    const { eventStart, eventEnd, bookingName, link } = item
    const eventDate = eventEnd
      ? `&start=${eventStart}&end=${eventEnd}`
      : `&start=${eventStart}`

    return `${link}&campaign_name=${bookingName}${eventDate}`
  })
}

export const isRouteRateAndReview = route => {
  return route === '/review/venue'
}

export const checkUserState = (to, from, store) => {
  /**
   * 1. Check filter sponsorship page
   * Page hold access [Sponsorship Requests, Booking Details]
   */
  // const sponsorshipPage = ['Sponsorship Requests', 'Booking Details']
  const { name: nameTo } = to;
  const { name: nameFrom } = from;
  if (nameFrom === 'Booking Details' && nameTo === 'Sponsorship Requests') {
  } else if (nameFrom === 'Sponsorship Requests' && nameTo === 'Booking Details') {
  } else if (nameFrom === 'Sponsorship Requests' && nameTo === 'Sponsorship Requests') {
  } else if (nameFrom && nameTo) {
    store.dispatch('account/CLEAR_USER_STATE')
  }
}