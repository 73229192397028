// Calculate additional total price with equation M or (D+DR) + (S+SR) +((Q-1)*X)
// D = Delivery Base Price
// DR = Delivery Regional Price
// S = Staffing Base Price
// SR = Staffing Regional Price
// A = Allow multiple purchases
// A= 1 when box is ticked (i.e. allow multiple purchases), 0 otherwise
// Q = Total number selected on profile by user (default is 1)
// P = Price
// X = Additional price per person
// M = Minimum price
// Price is maximum of:
// M or (D+DR) + (S+SR) +((Q-1)*X)
// So minimum price is default when the pricing formula is less than M

export default function (
  priceBreakdown = {
    agencies: {
      basedPrice: {
        agenciesPrice: {
          deliveryAgency: [],
          staffAgency: [],
        },
      },
      postalPrice: {
        agenciesPrice: {
          deliveryAgency: [],
          staffAgency: [],
        },
      },
    },
    vat: { percent: 0 },
  },
  quantity = 1,
  allowedMultiplePurchase = {
    status: false,
    price: 0,
    minimumPrice: 0,
  },
  // preview price only when sponsorhip request is select and price is set on add/edit campaign step 3
  sponsorshipPreviewPrice = null,
  paidPreviewPrice = null
) {
  const {
    agencies: { basedPrice, postalPrice },
    vat,
  } = priceBreakdown

  const totalDeliveryBasedPrice = (
    basedPrice.agenciesPrice.deliveryAgency || []
  ).reduce((prev, curr) => prev + curr.price, 0)
  const totalDeliveryPostalPrice = postalPrice.agenciesPrice
    ? (postalPrice.agenciesPrice.deliveryAgency || []).reduce(
        (prev, curr) => prev + curr.price,
        0
      )
    : 0

  const totalStaffBasedPrice = (
    basedPrice.agenciesPrice.staffAgency || []
  ).reduce((prev, curr) => prev + curr.price, 0)
  const totalStaffPostalPrice = postalPrice.agenciesPrice
    ? (postalPrice.agenciesPrice.staffAgency || []).reduce(
        (prev, curr) => prev + curr.price,
        0
      )
    : 0

  const { price, minimumPrice = 0 } = allowedMultiplePurchase
  const isShowPaidPreviewPrice =
    totalDeliveryBasedPrice <= 0 &&
    totalStaffBasedPrice <= 0 &&
    minimumPrice <= 0

  const STAFF_PRICE = totalStaffBasedPrice + totalStaffPostalPrice
  const DELIVERY_PRICE = totalDeliveryBasedPrice + totalDeliveryPostalPrice
  const ADJUST_QUANTITY = quantity - 1
  const allQuantity = DELIVERY_PRICE + price * ADJUST_QUANTITY
  const totalPrice = allQuantity + STAFF_PRICE
  const totalPaidPreviewPrice = paidPreviewPrice + price * ADJUST_QUANTITY
  const totalAdditionalPrice =
    minimumPrice > totalPrice ? minimumPrice : totalPrice
  const VAT_PRICE =
    (totalAdditionalPrice || totalPaidPreviewPrice) * (vat.percent / 100)
  const totalPriceIncVat = totalAdditionalPrice + VAT_PRICE
  const totalPaidPreviewPriceIncVat = totalPaidPreviewPrice + VAT_PRICE

  return {
    vat: vat,
    allQuantity: allQuantity,
    totalPrice: isShowPaidPreviewPrice ? 0 : totalAdditionalPrice,
    perPerson: isShowPaidPreviewPrice ? 0 : totalPriceIncVat / quantity,
    perPersonPaidPreview: totalPaidPreviewPriceIncVat / quantity,
    staffPrice: STAFF_PRICE,
    deliveryPrice: DELIVERY_PRICE,
    vatPrice: VAT_PRICE,
    totalPriceIncVat: isShowPaidPreviewPrice ? 0 : totalPriceIncVat,
    totalPaidPreviewPriceIncVat,
    totalPaidPreviewPrice,
    paidPreviewPrice,
    totalSponsorshipPreviewPrice: sponsorshipPreviewPrice * quantity,
    sponsorshipPreviewPrice,
  }
}
