<template>
  <nav :class="$options.name">
    <div>
      <ul v-show="false" class="menu">
        <li
          :class="{
            active: $route.name == 'campaign marketplace',
          }"
        >
          <a
            class="icon-marketplace"
            href="https://app.experiencethis.co/marketplace/subtheme/5f314f0fdc534700315e8ed9"
          >
            Marketplace
          </a>
        </li>
        <li>
          <RouterLink class="icon-events" to="/account/signup">
            Your Bookings
          </RouterLink>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import CONSTANTS from '../../config/url'

export default {
  name: 'GuestMenu',
  data: () => {
    return {
      pageURL: CONSTANTS.VENUES.PROFILE,
      groupProfilePageURL: CONSTANTS.VENUES.GROUP_PROFILE,
    }
  },
}
</script>

<style lang="scss" scoped src="../_css/sidebarmenu.scss"></style>

<style lang="scss" scoped>
.GuestMenu {
  &.SidebarNav {
    padding-top: 76px;
    background-color: $primary;
  }
  .menu {
    flex: 0 0 auto;
    padding: 0;
    &-venue-group {
      &-back {
        @include sf-font(1.6rem, $gray, 'semibold');
        padding: 1rem 3.3rem 0rem 3.3rem;

        a {
          color: $gray;
          @include montserrat-font($heading5, $shade2, 'regular');
          text-decoration: none;
          &:hover {
            @include montserrat-font($heading5, $primary, 'regular');
          }
        }
      }
    }
  }
  .menu-venue-group-back {
    margin-top: 44px;
  }
}
</style>
