<template>
  <theme-provider :theme="theme">
    <div id="app" :class="[navType]">
      <MainNav2
        v-if="
          routeName &&
          routeName.indexOf('account') === -1 &&
          routeName.indexOf('Submitted') === -1 &&
          !routeName.includes('review thanks')
        "
        :toggle-nav="toggleNav"
      ></MainNav2>
      <keep-alive include="cachedPages">
        <component :is="template">
          <RouterView />
        </component>
      </keep-alive>
      <Footer
        v-if="
          routeName &&
          routeName.indexOf('Submitted') === -1 &&
          !routeName.includes('review thanks')
        "
      ></Footer>
    </div>
  </theme-provider>
</template>

<script>
import { ThemeProvider } from 'vue3-styled-components'
import { mapActions } from 'vuex'

import * as theme from './components/ui/styled/theme'
// import MainNav from '@/components/MainNav'
import MainNav2 from '@/components/main_nav/main_nav_v2/Nav.vue'
import Footer from '@/components/Footer'

const defaultLayout = 'default'

export default {
  name: 'App',
  components: { 'theme-provider': ThemeProvider, MainNav2, Footer },
  data() {
    return {
      cachedPages: ['vunue booking details'],
      navType: 'Sticky_Bar',
      theme: theme.light,
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    },
    template() {
      return 'template-' + (this.$route.meta.template || defaultLayout)
    },
  },
  methods: {
    ...mapActions('ui', ['toggleNav']),
  },
}
</script>

<style lang="scss">
/* SETUP */
@import '~sanitize.css';

/* MAIN STYLES DEFINITION */
@import './styles/style.scss';

/* Rest of your styles... */
</style>

<style scoped lang="scss">
.main_menu_items {
  align-items: center;

  & > * {
    padding: 0 0.5rem;
  }
}

// PAGE-SPECIFIC STYLES
// Notification Pages
.Forbidden,
.BadRequest,
.NotFound {
  text-align: center;
  padding-top: 4rem;
  /deep/ .page-title {
    margin-bottom: 2rem;
  }
}
</style>
