import styled, { css } from 'vue3-styled-components'

export const MenuContainerWrapper = styled.div`
  ${({ theme: { primary }, active }) => css`
    cursor: pointer;
    position: relative;

    .Wrapper {
      display: flex;
      align-items: center;
      gap: 14px;
      justify-content: center;
    }

    ${active
      ? `padding: 10px 0;
      width: 116px;
    border: 2px solid ${primary};
    border-radius: 15px;
        background: white;
    `
      : `
          &:hover {
      background: #F3F3F3;
      width: 116px;
      padding: 10px 0;
      border-radius: 15px;
    }
      `}
  `}
`
