<template>
  <div class="template-sidebar">
    <SidebarNav></SidebarNav>

    <div :class="['main', $route.name.replaceAll(' ', '-')]">
      <div class="container-fluid">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarNav from '@/components/SidebarNav'

export default {
  name: 'TemplateLeftSidebar',
  components: { SidebarNav },
}
</script>

<style lang="scss" scoped>
.template-sidebar {
  .SidebarNav {
    display: none;
    -webkit-backface-visibility: hidden;
    @include for-desktop {
      display: block;
      left: 0;
      padding-top: 80px;
    }
  }
}
</style>
