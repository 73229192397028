import state from './campaign.state'
import getters from './campaign.getters'
import actions from './campaign.actions'
import mutations from './campaign.mutations'

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
