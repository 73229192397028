<template>
  <div :class="[$options.name, 'icon-cheveron-down']">
    <label v-if="label">{{ label }}</label>
    <select
      :name="name"
      :modelValue="modelValue"
      :disabled="isDisable"
      :placeholder="placeholder"
      class="custom-select"
      @change="onChange"
    >
      <option v-if="placeholder" value="none" disabled selected>
        {{ placeholder }}
      </option>
      <slot></slot>
    </select>
    <div class="arrow-icon">
      <i class="arrow-dropdown"></i>
    </div>
  </div>
</template>

<script>
"use strict";

export default {
  name: "Select",
  props: {
    label: { type: String },
    placeholder: { type: String },
    modelValue: { type: [String, Number, Boolean, Array, Object] },
    name: { type: String },
    isDisable: { type: Boolean },
  },

  methods: {
    onChange: function (event) {
      // Bind v-model
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>

<style lang="scss">
.custom-select {
  /* Hide the default arrow icon */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  /* Add your custom styles for the select box here */
}
.Select {
  @include sf-font(2rem, #3d3d3d);
  position: relative;
  padding: 18px 24px;
  border: 1px solid $shade2;

  &::before {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  select {
    width: 100%;
    @include sf-font(16px, $text_placeholder, "normal");
  }

  &.-hide-arrow::before {
    display: none;
  }
}
</style>
