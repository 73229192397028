import { datadogRum } from '@datadog/browser-rum'
import { config } from './index'
import { getVUEX, getUserGroup } from '../helpers/localStorage'
const { env = 'development', version, applicationId, clientToken } = config
const store = getVUEX()
const group = getUserGroup()
let userData = null
if (store) {
  const storeJson = JSON.parse(store)
  if (
    storeJson.account &&
    storeJson.account.user &&
    storeJson.account.user.email
  ) {
    userData = storeJson.account.user
  }
}
if (['demo', 'production'].includes(env)) {
  // eslint-disable-next-line no-unused-vars
  const service = env === 'production' ? 'prod-experiencethis' : 'et-frontend'
  const dd_env = env === 'production' ? 'prod' : env
  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.eu',
    env: dd_env,
    version,
    service,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    startSessionReplayRecordingManually: true,
    beforeSend: (event) => {
      // discard a RUM error if its message includes 'profile is not defined'
      const errorMessages = ['Uncaught "ResizeObserver loop completed with undelivered notifications."', 'Uncaught "Script error."'];
      if (event.type === 'error' && errorMessages.includes(event.error.message)) {
        return false
      }

      return true;
    },
  })
  if (userData) {
    datadogRum.setUser({
      id: userData ? userData._id : '',
      name: userData ? userData.fullname : 'non-login',
      email: userData ? userData.email : 'non-login',
      type: group || '',
    })
  }
  datadogRum.startSessionReplayRecording()
}
export default datadogRum