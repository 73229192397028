<template>
  <component
    :is="currentPageMenu"
    :class="[$options.name, ipadLandscape, { 'is-open': isNavOpen }]"
  ></component>
</template>

<script>
import VenueMenu from '@/components/sidebar_menu/VenueMenu'
import BrandMenu from '@/components/sidebar_menu/BrandMenu'
import AgencyMenu from '@/components/sidebar_menu/AgencyMenu'
import GuestMenu from '@/components/sidebar_menu/GuestMenu'

import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'SidebarNav',
  components: { VenueMenu, BrandMenu, AgencyMenu, GuestMenu },
  data: function () {
    return {
      ipadLandscape: '',
    }
  },
  computed: {
    ...mapState('ui', ['isNavOpen']),
    ...mapGetters('account', ['userGroup']),
    currentPageMenu() {
      let menu
      switch (this.userGroup) {
        case 'brand':
          menu = BrandMenu
          break
        case 'agency':
          menu = AgencyMenu
          break
        case 'venue':
          menu = VenueMenu
          break
        case 'venueGroup':
          menu = VenueMenu
          break
        default:
          menu = GuestMenu
          break
      }
      return menu
    },
  },
  watch: {
    $route() {
      this.toggleNav({
        isOpen: false,
      })
    },
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.ipadLandscape = 'ipadLandscape'
    } else if (
      /Macintosh/i.test(navigator.userAgent) &&
      navigator.maxTouchPoints > 1
    ) {
      this.ipadLandscape = 'ipadLandscape'
    }
  },
  methods: {
    ...mapActions('ui', ['toggleNav']),
  },
}
</script>

<style lang="scss" scoped>
.SidebarNav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
     width: 240px !important;
  &.is-open {
    display: block !important;
    position: fixed;
    top: 0px;
    bottom: initial !important;
    height: auto !important;
    z-index: 100;
    background-color: unset;
    box-shadow: 0 5px 15px 1px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    @include for-mobile {
      top: 58px;
    }
    @include for-mobile-landscape {
      top: 80px;
    }
    @include for-tablet {
      top: 70px;
    }
  }

  /deep/ .menu {
    padding: 3rem 0;
    @include for-mobile-to-tablet {
      overflow-y: scroll;
    }
    li {
      white-space: nowrap;
    }
  }
}
.ipadLandscape {
  top: 10px;
}
</style>
