import styled from 'vue3-styled-components'

let props = {
  image: String,
  isOpen: Boolean,
}
let tmpBasketContainer
export const BasketMain = styled('div', props)`
position: absolute;
width: 100%;
height: 100%
right: 0;
top: 0;
cursor: auto;
z-index: 100;
min-height: 100vh;
${tmpBasketContainer}{
  ${({ isOpen = false }) => (isOpen ? 'display: unset;' : 'display: none;')}
}
`

export const BasketContainer = styled('div', props)`
  ${({ theme: { shades, shadows, breakpoints } }) => `
  position: absolute;
  box-shadow: ${shadows};
  background-color: ${shades[0]};
  padding: 25px 0;
  width: 100%;
  top: 80px;
  @media (${breakpoints.tablet}) {
    width: 400px;
    right: 25px;
  }
`}
`
tmpBasketContainer = BasketContainer

export const HeaderBasket = styled('div')`
  ${({ theme: { primary } }) => `
  margin-bottom: 15px;
  color: ${primary};
  `}
`

export const BodyBasket = styled('div')`
  ${({ theme: { shades } }) => `
  margin: 10px;
  border-top: 1px solid ${shades[1]};
  border-bottom: 1px solid ${shades[1]};
  height: 250px;
  overflow-x: hidden;
  overflow-y: scroll;
`}
`

export const FooterBasket = styled('div')`
  ${({ theme: { primary } }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${primary};
`}
`

export const MainBasket = styled('div')`
  ${({
    theme: {
      font: { sizes },
    },
  }) => `
  margin-left: 10px;
  margin-bottom: 10px;
  font-size: ${sizes.heading[2]};
`}
`

export const SubBasket = styled('div')`
  ${({ theme: { shades } }) => `
  display: flex;
  margin-bottom: 10px;
  background-color: ${shades[6]};
  height: 50px;
  align-items: center;
`}
`

export const TextSub = styled('div')`
  ${({
    theme: {
      font: { sizes },
    },
  }) => `
  margin-left: 10px;
  font-size: ${sizes.text[4]};
  `}
`

export const ItemImage = styled('div', props)`
  ${({ image }) => `
width: 60px;
height: 40px;
content: url('${image}');
`}
`

export const ItemName = styled('div')`
  margin-left: 10px;
  color: unset;
`

export const item = styled('div')`
  ${({
    theme: {
      shades,
      font: { sizes, family, weight },
    },
  }) => `
  padding-left: 15px;
  background-color: ${shades[8]};
  font-size: ${sizes.text[2]};
  font-weight: ${weight.bold};
  font-family: ${family[1]};
  color: ${shades[7]};
  padding: 15px 0 15px 15px;
  `}
`
export const quantity = styled('div')`
  ${({
    theme: {
      shades,
      font: { sizes, family, weight },
    },
  }) => `
  text-align: center;
  background-color: ${shades[8]};
  font-size: ${sizes.text[2]};
  font-weight: ${weight.bold};
  font-family: ${family[1]};
  color: ${shades[7]};
  padding: 15px 0;
  `}
`
export const price = styled('div')`
  ${({
    theme: {
      shades,
      font: { sizes, family, weight },
    },
  }) => `
text-align: center;
background-color: ${shades[8]};
font-size: ${sizes.text[2]};
font-weight: ${weight.bold};
font-family: ${family[1]};
color: ${shades[7]};
padding: 15px 0;
`}
`

export const ItemPrice = styled('div')`
  align-self: center;
  text-align: center;
`

export const ItemQuantity = styled('div')`
  align-self: center;
  text-align: center;
`

export const EmptyText = styled('div')`
  display: flex;
  height: 150px;
  align-items: center;
  justify-content: center;
`

export const ItemGroup = styled('div')`
  display: flex;
  align-items: center;
`

export const total = styled('div')`
  ${({
    theme: {
      font: { sizes },
    },
  }) => `
  margin: 15px;
  font-size: ${sizes.text[5]};
`}
`

export const EditBtn = styled('div')`
  ${({
    theme: {
      font: { weight },
      shades,
    },
  }) => `
  border: 1px solid ${shades[4]};
  padding: 7px 60px;
  font-weight: ${weight.bold};
  cursor: pointer;
`}
`
