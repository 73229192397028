import { createActionHandlerQuery } from '@/helpers/query'

const assignStateAction =
  (mutationName) =>
  async ({ commit }, payload) => {
    commit(`${mutationName}`, payload)
  }

export default {
  // query
  queryAssetList: createActionHandlerQuery('getAssetList'),
  queryStaffTypesList: createActionHandlerQuery('getStaffTypesList'),
  queryStockList: createActionHandlerQuery('getStockList'),
  queryEventTypeList: createActionHandlerQuery('getEventTypeList'),
  queryBrandList: createActionHandlerQuery('getBrandList'),
  queryCampaignsOnMarketplace: createActionHandlerQuery(
    'getCampaignsOnMarketplace',
    'campaignFilterMarketplace'
  ),
  queryCampaignOfferGroup: createActionHandlerQuery('campaignOfferGroup'),
  queryTaskType: createActionHandlerQuery('taskTypeMany'),
  // set state
  setStateStaff: assignStateAction('setStateStaff'),
  setStateAsset: assignStateAction('setStateAsset'),
  setStateStock: assignStateAction('setStateStock'),
  setStakeholders: assignStateAction('setStakeholders'),
  setHandleError: assignStateAction('setHandleError'),
}
