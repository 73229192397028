<template>
  <div class="template-sidebar">
    <SidebarNav></SidebarNav>

    <div class="main">
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarNav from '@/components/SidebarNavVenueGroup'

export default {
  name: 'TemplateVenueGroupSidebar',
  components: { SidebarNav },
}
</script>

<style lang="scss" scoped>
.template-sidebar {
  .SidebarNav {
    display: none;
    @include for-desktop {
      display: block;
      left: 0;
      padding-top: 80px;
    }
  }
}
</style>
