export default {
  getAssetList: (state) => {
    return state.assetList
  },
  getStockList: (state) => {
    return state.stockList
  },
  getStockSelected: (state) => {
    return state.stockSelected.map((item) => {
      return { ...item, amount: item.amount ? parseInt(item.amount) : 0 }
    })
  },
  getVenueIds: (state) => {
    return state.venueIds
  }
}
