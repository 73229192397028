<template>
  <footer>
    <div id="et-footer" class="et-footer">
      <div class="container-fluid">
        <div class="col-12">
          <div class="row">
            <div class="col-xl-6 col-md-6 col-12 link-footer">
              <p class="menu-label">
                <a href="https://experiencethis.co/about-us/" target="_blank">
                  About Us
                </a>
                <label class="secondary-font">|</label>
                <a href="https://experiencethis.co/contact-us/" target="_blank">
                  Contact Us
                </a>
                <label class="secondary-font">|</label>
                <a href="https://experiencethis.co/delivery/" target="_blank">
                  Delivery
                </a>
                <label class="secondary-font">|</label>
                <a
                  href="https://intercom.help/experience-this/en/collections/2389352-faqs"
                  target="_blank"
                >
                  FAQs
                </a>
              </p>
              <p class="menu-label">
                <a
                  href="https://experiencethis.co/privacy-policy-2/"
                  target="_blank"
                >
                  Privacy & Cookie Policy
                </a>
                <label class="secondary-font">|</label>
                <a
                  href="https://experiencethis.co/terms-and-conditions-2/"
                  target="_blank"
                >
                  Terms And Conditions
                </a>
                <label class="secondary-font">|</label>
                <a
                  href="https://experiencethis.co/venue-terms/"
                  target="_blank"
                >
                  Terms
                </a>
              </p>
              <div class="copyright-label">
                <label>
                  © Copyright 2019 Experience Lab LTD. All rights reserved.
                </label>
              </div>
            </div>
            <div class="col-xl-6 col-md-6 col-12 logo-footer">
              <div class="logo-group">
                <a
                  style="margin-right: 20px"
                  href="https://www.facebook.com/experiencethis.co/"
                  target="_blank"
                >
                  <img
                    src="https://s3.eu-west-2.amazonaws.com/static.uat.experiencethis.co/email_templates/f_logo_RGB-White_250.png"
                  />
                </a>
                <a
                  href="https://www.instagram.com/expthisgram/?hl=en"
                  target="_blank"
                >
                  <img
                    src="https://s3.eu-west-2.amazonaws.com/static.uat.experiencethis.co/email_templates/instagram.png"
                  />
                </a>
              </div>
              <div class="logo-experience">&nbsp;</div>
              <p class="link-drinkaware">
                Please drink responsibly. For the full facts please visit&nbsp;
                <a
                  class="link"
                  href="https://www.drinkaware.co.uk"
                  target="_blank"
                >
                  https://www.drinkaware.co.uk
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.et-footer {
  @include sf-font(10pt, $white);
  background-color: $black;
  padding: 25px 0;
  @include for-mobile {
    padding: 20px 0;
  }

  .link-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .menu-label {
      a {
        @include sf-font(10pt, $white);
        text-decoration: none;
      }
      @include for-mobile {
        display: flex;
        align-items: baseline;
        justify-content: center;
        a {
          padding-left: 1rem;
          display: block;
          margin-top: 1rem;
          margin-right: 7px;
          @include sf-font(8pt, $white);
        }
      }
      @include for-mobile-landscape {
        text-align: center;
      }
    }

    .secondary-font {
      color: $orange2;
      @include for-mobile {
        //display: none;
      }
    }

    .copyright-label {
      @include sf-font(8pt, $white);
      @include for-mobile {
        margin-top: 1rem;
        text-align: center;
        @include sf-font(5pt, $white);
      }
      @include for-mobile-landscape {
        text-align: center;
      }
    }
  }

  .logo-footer {
    .logo-group {
      display: flex;
      justify-content: flex-end;
      margin-right: 98px;
      @include for-mobile {
        margin-top: 30px;
        margin-right: unset;
        justify-content: flex-start;
      }
    }
    .logo-experience {
      width: 100%;
      background-image: url('/public/icon/svg/et-logo-white.svg');
      background-position: center right 100px;
      background-size: 50%;
      min-height: 50px;
      margin: 15px 0;
      @include for-tablet {
        background-size: 70%;
      }
      @include for-mobile {
        padding-top: 50px;
        background-size: 70%;
      }
      @include for-mobile-landscape {
        padding-top: 100px;
      }
    }
    .link-drinkaware {
      display: flex;
      justify-content: flex-end;
      margin-right: 98px;
      align-items: center;
      color: inherit !important;
      @include for-mobile-to-tablet {
        flex-direction: column;
      }
      .link {
        text-decoration: none;
        color: inherit !important;
        font-size: inherit !important;
        font-weight: inherit !important;
        @include for-mobile-to-tablet {
          align-self: flex-start;
        }
      }
    }
  }
}
</style>
