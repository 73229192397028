<template>
  <MenuContainerWrapper :active="item.active" @click="Open" @focusout="Close">
    <div ref="menuRef" class="Wrapper">
      <PrimaryTextSmall class="no-margin-bottom">{{ name }}</PrimaryTextSmall>
      <el-icon v-if="isShowArrow"><ArrowDown /></el-icon>
      <Option
        v-show="item.active"
        :options="item.options"
        @handleCloseMenu="CloseMenu"
      />
    </div>
  </MenuContainerWrapper>
</template>

<script>
import * as styles from './styles'
import * as texts from '@/components/ui/styled/text/index'
import Option from '../option/option.vue'

export default {
  name: 'MenuItem',
  components: {
    ...styles,
    ...texts,
    Option,
  },
  props: {
    item: {
      type: Object,
      default: () => ({ active: false, options: [] }),
    },
    name: {
      type: String,
      default: '',
    },
    isShowArrow: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.addEventListener('click', this.handleClickOutside)
    })
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    Open() {
      this.item.active = !this.item.active
    },
    CloseMenu() {
      this.item.active = false
    },
    handleClickOutside(event) {
      const menuElement = this.$refs.menuRef
      if (menuElement && !menuElement.contains(event.target)) {
        this.CloseMenu()
      }
    },
  },
}
</script>
