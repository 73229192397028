export default {
  setTeamMembersSuccess: (state, payload) => {
    state.teamMembers = {
      items: [...payload.items],
      count: payload.count,
      isLoaded: true,
    }
  },
  getTeamMemberPoSuccess: (state, payload) => {
    state.teamMembers = {
      items: [...payload.getTeamMemberPo.items],
      count: payload.getTeamMemberPo.count,
      isLoaded: true,
    }
    state.error = null
  },
  getTeamMemberSumSuccess: (state, payload) => {
    state.teamMembers = {
      items: [...state.teamMembers.items, ...payload.getTeamMemberPo.items],
      count: state.teamMembers.count + payload.getTeamMemberPo.count,
      isLoaded: true,
    }
    state.error = null
  },
  addTeamMembersSuccess: (state, payload) => {
    state.teamMembers = { ...payload, isLoaded: true }
    state.error = null
  },

  addTeamMembersError: (state, payload) => (state.error = payload),
  setTeamMembersError: (state, payload) => (state.error = payload),
  getTeamMemberPoError: (state, payload) => (state.error = payload),
}
