import actions from './team.actions'
import mutations from './team.mutations'
import state from './team.state'
import getters from './team.getters'

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
