import {
  createActionHandlerQuery,
  createActionHandlerMutate,
} from '@/helpers/query'

export default {
  getPendingRequests: createActionHandlerQuery(
    'setPendingRequests',
    'getTasksInBooking'
  ),
  getConfirmedRequests: createActionHandlerQuery(
    'setConfirmedRequests',
    'getTasksInBooking'
  ),
  getRejectedRequests: createActionHandlerQuery(
    'setRejectedRequests',
    'getTasksInBooking'
  ),
  createConfirmRequest: createActionHandlerMutate('createConfirmRequest'),
  createRejectRequest: createActionHandlerMutate('createRejectRequest'),
}
