/**
 * createActionHandler : Higher order function to return a new function for
 * sponsorship request action.
 * @param {String} mutationName - Name of mutation to be commit.
 */
import {
  createActionHandlerQuery,
  createActionHandlerMutate,
} from '@/helpers/query'

export default {
  getBudgets: createActionHandlerQuery('getBudgets', 'searchPurchaseOrder'),
  addBudget: createActionHandlerMutate('addBudget'),
  updateBudget: createActionHandlerMutate('updateBudget'),
  editBudget: createActionHandlerMutate('editBudget'),
  getPOCode: createActionHandlerQuery('getPOCode'),
  getEmailList: createActionHandlerQuery('getEmailList', 'getTeamMember'),
  sendEmail: createActionHandlerMutate('sendEmail'),
  clearPOCode(context, payload) {
    context.commit('clearPOCode', payload)
  }
}
