import state from './experiences.state'
import mutations from './experiences.mutations'
import actions from './experiences.actions'
import getters from './experiences.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
