export default {
  staffSelected: [],
  staffTypesList: [],
  assetSelected: [],
  assetList: [],
  stockSelected: [],
  stockList: [],
  eventTypeList: {},
  tmpEventTypeList: {},
  brandList: {},
  tmpBrandList: {},
  startDateFilter: '',
  endDateFilter: '',
  dateFilter: {},
  stringDate: '',
  eventTypeFilter: [],
  brandFilter: [],
  stakeholders: [],
  varyAssets: [],
  rescheduleAssets: [],
  verifyInventory: [],
  campaignsOnMarketplaceList: {
    items: [],
    count: 0,
    isLoaded: false,
  },
  campaignOfferGroup: [],
  tasksList: [],
  venueIds: [],
  handleError: null,
  error: null,
}
