
export const isMobile = () => {
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints;

  return isTouchDevice;
}

export const delayMiliseconds = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const isIpadHorizontal = () => {
  return (
    window.innerHeight < window.innerWidth && /iPad/i.test(navigator.userAgent)
  )
}

export const isOnlyMobile = () => {
  return window.matchMedia('(max-width: 767px)').matches;
}



export const isIpad = () => {
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints;

  return isTouchDevice && !isOnlyMobile();

};
