export const MISSING_IMAGE = 'MISSING_IMAGE'
export const MISSING_STAFF_OR_ASSET = 'MISSING_STAFF_OR_ASSET'
export const MISSING_BOTH = 'MISSING_BOTH'
export const MISSING_IMAGE_INVENTORY_ETA = 'MISSING_IMAGE_INVENTORY_ETA'
export const MISSING_ETA = 'MISSING_ETA'
export const MISSING_IMAGE_ETA = 'MISSING_IMAGE_ETA'
export const MISSING_INVENTORY_ETA = 'MISSING_INVENTORY_ETA'

export const validateCreateCampaign = () => {}

export const showWarningMessage = (type) => {
  switch (type) {
    case MISSING_IMAGE:
      return `Please upload a minimum of 5 images in order to complete your profile.`
    case MISSING_STAFF_OR_ASSET:
      return `Please note you haven't included any staff or assets.`
    case MISSING_BOTH:
      return `Please upload a minimum of 5 images in order to complete your profile.<br/>Please note you haven't included any staff or assets.`
    case MISSING_IMAGE_ETA:
      return `Please upload a minimum of 5 images in order to complete your profile.<br/>The dates & times section has missing information.`
    case MISSING_ETA:
      return `The dates & times section has missing information.`
    case MISSING_IMAGE_INVENTORY_ETA:
      return `Please upload a minimum of 5 images in order to complete your profile.<br/>Please note you haven't included any staff or assets.<br/>The dates & times section has missing information.`
    case MISSING_INVENTORY_ETA:
      return `Please note you haven't included any staff or assets.<br/>The dates & times section has missing information.`
    default:
      return `Please upload a minimum of 5 images in order to complete your profile.<br/>Please note you haven't included any staff or assets.`
  }
}
